import { Injectable, OnInit } from '@angular/core';
import {
    CognitoUserAttribute,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserSession
} from 'amazon-cognito-identity-js';
import { config, CognitoIdentityCredentials, Credentials } from 'aws-sdk/global';
import { Observable } from 'rxjs';
import { User, Response } from '../../models';
import { ErrorConstants, SuccessConstants, CongitoUtility } from '../../constants';
import { bool } from 'aws-sdk/clients/signer';

const userPool = CongitoUtility.GetPoolInfo();

@Injectable()
export class AuthService implements OnInit {
    registeredUser: CognitoUser;
    isLoggedIn = false;
    cognitoUser: CognitoUser;
    token = '';
    loginRole = '';
    session: CognitoUserSession;
    constructor() {
        config.region = CongitoUtility.GetCognitoRegion();
        config.credentials = new CognitoIdentityCredentials({
            IdentityPoolId: CongitoUtility.GetIdentityPoolId(),
            Logins: {}
        });
        this.initAuth();
    }
    ngOnInit() { }
    getCognitoUser(userName: string): CognitoUser {
        const userData = {
            Username: userName.trim(),
            Pool: userPool
        };
        const currentUser = new CognitoUser(userData);
        return currentUser;
    }
    signUp(signUpDetails: any): Observable<any> {
        const promise = new Observable<any>((observer) => {
            const user: User = {
                username: signUpDetails.userName.trim(),
                email: signUpDetails.email,
                password: signUpDetails.password
            };
            const attrList: CognitoUserAttribute[] = this.buildAttrList(signUpDetails);
            userPool.signUp(user.username, user.password, attrList, null, (err, result) => {
                if (err) {
                    const response: Response = { error: err.message, failure: true, success: false, result: null };
                    observer.next(response);
                } else {
                    this.registeredUser = result.user;
                    const response: Response = {
                        error: null, failure: false, success: true, result: user.username +
                            'You are registered successfully'
                    };
                    observer.next(response);
                }
            });
        });
        return promise;
    }

    buildAttrList(signUpDetails: any): Array<CognitoUserAttribute> {
        const attributes: CognitoUserAttribute[] = [];
        Object.keys(signUpDetails).forEach(key => {
            if (key === 'cPassword' || key === 'password' || key === 'userName') {
                return;
            } else if (key.toLowerCase() === 'tier'  || key.toLowerCase() === 'role') {
                attributes.push(new CognitoUserAttribute({ Name: 'custom:' + key, Value: signUpDetails[key] }));
            } else {
                attributes.push(new CognitoUserAttribute({ Name: key.toLowerCase(), Value: signUpDetails[key] }));
            }
        });
        return attributes;
    }

    confirmUser(userName: string, code: string): Observable<any> {
        const observable = new Observable<any>((observer) => {
            const cognitUser = this.getCognitoUser(userName);
            cognitUser.confirmRegistration(code, true, (err, result) => {
                if (err) {
                    const response: Response = { error: ErrorConstants.Cognito[err.code], failure: true, success: false, result: null };
                    observer.next(response);
                } else {
                    const response: Response = {
                        error: null, failure: false, success: true,
                        result: SuccessConstants.Messages.Signup_Suceess_Message
                    };
                    observer.next(response);
                }
            });
        });
        return observable;
    }

    signIn(username: string, password: string): Observable<any> {
        const promise = new Observable<any>((observer) => {
            const authData = {
                Username: username,
                Password: password
            };
            const authDetails = new AuthenticationDetails(authData);
            const cognitoUser = this.cognitoUser = this.getCognitoUser(username);
            cognitoUser.authenticateUser(authDetails, {
                onSuccess: (result: CognitoUserSession) => {
                    const response: Response = { error: null, failure: false, success: true, result: result };
                    this.loginRole = result.getIdToken().decodePayload()['custom:Role'];
                    this.isLoggedIn = true;
                    this.refreshConfig(result.getIdToken().getJwtToken());
                    observer.next(response);
                },
                onFailure: (err) => {
                    this.isLoggedIn = false;
                    console.log(err);
                    const response: Response = {
                        error: ErrorConstants.Cognito[err.code], failure: true,
                        success: false, result: null
                    };
                    observer.next(response);
                },
                newPasswordRequired: (userAttributes) => {
                    const response: Response = {
                        // tslint:disable-next-line:max-line-length
                        error: ErrorConstants.Cognito.New_Password_Challenge_Message,
                        failure: true, success: false, result: null
                    };
                    this.isLoggedIn = false;
                    observer.next(response);
                }
            });
        });
        return promise;
    }

    getAuthenticatedUser() {
        return (this.cognitoUser = (this.cognitoUser || userPool.getCurrentUser()));
    }

    getUserSession(promise: any) {
        try {
            return userPool.getCurrentUser().getSession(promise);
        } catch (error) {
            promise(error, null);
            return;
        }
    }

    logout(): void {
        const userPoolInfo = this.getAuthenticatedUser();
        if (userPoolInfo) {
            userPoolInfo.globalSignOut({
                onFailure: (err) => {
                    console.log(err);
                },
                onSuccess: (msg) => {
                    console.log(msg);
                }
            });
        }
        this.refreshConfig('');
        this.isLoggedIn = false;
        this.loginRole = '';
    }

    isAuthenticated(): Observable<boolean> {
        const user = this.getAuthenticatedUser();
        const obs = Observable.create((observer) => {
            if (!user) {
                this.isLoggedIn = false;
                observer.next(false);
            } else {
                user.getSession((err, session: CognitoUserSession) => {
                    if (err) {
                        this.isLoggedIn = false;
                        observer.next(false);
                    } else {
                        if (session.isValid()) {
                            this.isLoggedIn = true;
                            this.token = session.getIdToken().getJwtToken();
                            this.loginRole = session.getIdToken().decodePayload()['custom:Role'];
                            this.refreshConfig(this.token);
                            this.session = session;
                            observer.next(true);
                        } else {
                            this.token = '';
                            this.isLoggedIn = false;
                            this.session = null;
                            observer.next(false);
                        }
                    }
                });
            }
        });
        return obs;
    }

    refreshSession(needsRefresh: boolean): Observable<string> {
        const refreshToken = this.session.getRefreshToken();
        const observable = Observable.create((observer) => {
            if ((config.credentials as Credentials).needsRefresh() || needsRefresh) {
                this.getAuthenticatedUser()
                    .refreshSession(refreshToken, (err, sess) => {
                        if (err) {
                            console.log(err);
                            observer.next(null);
                        } else {
                            this.session = sess;
                            this.token = this.session.getIdToken().getJwtToken();
                            this.refreshConfig(this.token);
                            observer.next(this.token);
                        }
                    });
            } else {
                observer.next(this.token);
            }
        });
        return observable;
    }

    getUserPool() {
        return userPool.getUserPoolId();
    }

    refreshConfig(param: string) {
        (config.credentials as CognitoIdentityCredentials).params['Logins'][CongitoUtility.GetConfigKey()] = param;
    }
    /* Reset Password Start*/
    resetPassword(newPassword: string, userName: string): Observable<any> {
        const promise = new Observable((observer) => {
            const currentUser = this.getCognitoUser(userName);
            this.cognitoUser.completeNewPasswordChallenge(newPassword, [], {
                onSuccess: (session: CognitoUserSession) => {
                    const response: Response = {
                        error: null, failure: false, success: true,
                        result: SuccessConstants.Messages.Reset_Success_Message
                    };
                    observer.next(response);
                },
                onFailure: (err) => {
                    const response: Response = {
                        error: ErrorConstants.Cognito.UnknownError, failure: true,
                        success: false, result: null
                    };
                    observer.next(response);
                },
                customChallenge: (challengeParameters: any) => {
                    const response: Response = {
                        error: null, failure: false, success: true,
                        result: SuccessConstants.Messages.Reset_Success_Message
                    };
                    observer.next(response);
                }
            });
        });
        return promise;
    }
    /* Reset Password End*/

    /*Change Password Start*/
    changePassword(userName: string, oldPassword: string, newPassword: string): Observable<any> {
        const promise = new Observable((observer) => {
            const currentUser = this.getCognitoUser(userName);
            this.cognitoUser.changePassword(oldPassword, newPassword, (error, success) => {
                if (error) {
                    const response: Response = {
                        error: ErrorConstants.Cognito.NotAuthorizedException, failure: true,
                        success: false, result: null
                    };
                    observer.next(response);
                } else {
                    const response: Response = {
                        error: null, failure: false,
                        success: true, result: SuccessConstants.Messages.Reset_Success_Message
                    };
                    observer.next(response);
                }
            });
        });
        return promise;
    }
    /*Reset Password End*/

    /*Forgot Password Start*/
    forgotPasswordInitiation(userName: string): Observable<any> {
        const promise = new Observable((observer) => {
            const currentUser = this.getCognitoUser(userName);
            currentUser.forgotPassword({
                onSuccess: (success) => {
                    console.log('Successfully initiated reset password');
                },
                onFailure: (error: any) => {
                    const response: Response = {
                        error: ErrorConstants.Cognito[error.code], failure: true,
                        success: false, result: null
                    };
                    observer.next(response);
                },
                inputVerificationCode: (data) => {
                    const response: Response = {
                        error: null, failure: false, success: true,
                        result: SuccessConstants.Messages.Forgot_Password_Initiated_Message
                    };
                    observer.next(response);
                }
            });
        });
        return promise;
    }

    forgotPasswordConfirmation(verificationCode: string, newPassword: string, userName: string): Observable<any> {
        const promise = new Observable((observer) => {
            const currentUser = this.getCognitoUser(userName);
            currentUser.confirmPassword(verificationCode, newPassword, {
                onSuccess: () => {
                    const response: Response = {
                        error: null, failure: false,
                        success: true, result: SuccessConstants.Messages.Forgot_Password_Success_Message
                    };
                    observer.next(response);
                },
                onFailure: (error: any) => {
                    const response: Response = {
                        error: ErrorConstants.Cognito[error.code], failure: true,
                        success: false, result: null
                    };
                    observer.next(response);
                }
            });
        });
        return promise;
    }
    /*Forgot Password End*/

    verifyMobileDevice(userName: string): Observable<any> {
        this.cognitoUser = this.cognitoUser || this.getCognitoUser(userName);
        const observable = new Observable((observer) => {
            this.cognitoUser.getAttributeVerificationCode('phone_number', {
                onFailure: (error: any) => {
                    const resp: Response = {
                        error: ErrorConstants.Cognito[error.code], failure: true, result: null, success: false
                    };
                    observer.next(resp);
                },
                onSuccess: () => {
                    const resp: Response = {
                        error: null, failure: false, result: 'No verification is required', success: true
                    };
                    observer.next(resp);
                },
                inputVerificationCode: () => {
                    const resp: Response = {
                        error: null, failure: false,
                        result: { 'mobileVerificationRequired': true, message: SuccessConstants.Messages.Mobile_Verification_Message },
                        success: true
                    };
                    observer.next(resp);
                }
            });
        });
        return observable;
    }

    confirmMobileDevice(userName: string, otp: string): Observable<any> {
        this.cognitoUser = this.cognitoUser || this.getCognitoUser(userName);
        const observable = new Observable((observer) => {
            this.cognitoUser.verifyAttribute('phone_number', otp, {
                onFailure: (err: any) => {
                    const resp: Response = {
                        error: ErrorConstants.Cognito[err.code], failure: true, success: false
                    };
                    observer.next(resp);
                },
                onSuccess: (message) => {
                    const resp: Response = {
                        error: null, failure: false, success: true, result: SuccessConstants.Messages.Mobile_Verification_Success_Message
                    };
                    observer.next(resp);
                }
            });
        });
        return observable;
    }

    initAuth() {
        this.isAuthenticated().subscribe((response) => {
            this.isLoggedIn = response;
        });
    }
}
