import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-confirm',
    templateUrl: 'confirm.component.html',
    styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent {
    constructor(public dialogRef: MatDialogRef<ConfirmComponent>) { }
    onClose(response: boolean) {
        this.dialogRef.close(response);
    }
}
