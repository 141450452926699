import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gridSort'
})
export class GridSortPipe implements PipeTransform {
    transform(value: Array<any>, key: string, sortOrder: string) {
        if (sortOrder && key) {
            value = value.sort((a: any, b: any) => {
                if (!a[key]) {
                    return 1;
                } else if (!b[key]) {
                    return -1;
                } else if (a[key] === b[key]) {
                    return 0;
                }
                if (sortOrder === 'asc') {
                    if (a[key] && b[key] && this.checkCondition(typeof a[key], a[key], b[key], sortOrder)) {
                        return 1;
                    } else { return -1; }
                } else if (sortOrder === 'desc') {
                    if (a[key] && b[key] && this.checkCondition(typeof a[key], a[key], b[key], sortOrder)) {
                        return 1;
                    } else { return -1; }
                }
            });
        }
        return value;
    }

    checkCondition(dataType: string, param1: any, param2: any, sortOrder: string): boolean {
        let result = false;
        switch (dataType) {
            case 'string': result = sortOrder === 'desc' ?
                param1.toLowerCase() < param2.toLowerCase() :
                param1.toLowerCase() > param2.toLowerCase();
                break;
            case 'number': result = sortOrder === 'desc' ?
                param1 > param2 :
                param1 < param2;
                break;
            case 'date': result = sortOrder === 'desc' ?
                new Date(param1).getTime() - new Date(param2).getTime() > 0 :
                new Date(param1).getTime() - new Date(param2).getTime() < 0;
                break;
            default:
                break;
        }
        return result;
    }
}
