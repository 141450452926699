import { Attribute, forwardRef, Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[validateEqual][formControlName]',
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidator), multi: true }]
})
export class EqualValidator implements Validator {
    constructor(@Attribute('validateEqual') public validateEqual: string,
        @Attribute('reverse') public reverse: boolean = false) { }

    validate(c: AbstractControl): { [key: string]: any } {
        // self value
        const presentValue = c.value;

        // Reference Control
        const referenceControl = c.root.get(this.validateEqual);

        if (referenceControl) {
            // set validation to self - reverse set to false
            if (!this.reverse) {
                if (presentValue !== referenceControl.value) {
                    return { validateEqual: false };
                }
            } else if (this.reverse) { // set validation to reference control - reverse set to true
                // delete error depend on the other errors in reference control on validation equal
                if (referenceControl.errors && presentValue === referenceControl.value) {
                    delete referenceControl.errors['validateEqual'];
                    if (!Object.keys(referenceControl.errors).length) { referenceControl.setErrors(null); }
                }

                // value not equal
                if (presentValue !== referenceControl.value) {
                    referenceControl.setErrors({ validateEqual: false });
                }
            }
        }
        return null;
    }
}
