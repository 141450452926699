import { Injectable } from '@angular/core';
import { ApiDictionary, Resources, ErrorConstants, SuccessConstants } from '../../constants';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Response, SaveUserNotifications , UserNotifications } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class UserNotificationService {
    constructor(private apiService: ApiService) { }

    getUserNotifications(userName: string): Observable<any> {
        let apiUrl = ApiDictionary.getUserSettings.url;
        apiUrl = Resources.getUserSettings + apiUrl + '/' + userName;
        const param = this.apiService.BindParamsToUrl(apiUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(param).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: this.populateUserSettings(result.UserSettings, result)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateUserSettings(userSettings: Array<any>, notificationSetting: any): Array<any> {
        const settings = [];
        for (let index = 0; index < userSettings.length; index++) {
            const element = userSettings[index];
            const setting: any = {
                name: element.Name, value: element.Value === 'true' ? true : false,
            };
            if (element.Name.indexOf('SMS') > -1) {
                setting.isDisabled = !notificationSetting.AllowSMSOverride;
            }
            if (element.Name.indexOf('Email') > -1) {
                setting.isDisabled = !notificationSetting.AllowEmailOverride;
            }
            if (element.Name.indexOf('Push') > -1) {
                setting.isDisabled = !notificationSetting.AllowPushNotificationOverride;
            }
            settings.push(setting);
        }
        return settings;
    }

    saveUserSettings(userName: string, userSettings: Array<any>): Observable<any> {
        let apiUrl = ApiDictionary.saveUserSettings.url;
        apiUrl = Resources.saveUserSettings + apiUrl;
        const observable = new Observable((observer) => {
            const request: SaveUserNotifications = {
                UserName: userName,
                UserNotifications: this.populateSaveUserSettings(userSettings)
            };
            this.apiService.postData(apiUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null,
                        success: true, result: result.BooleanValue ? SuccessConstants.Messages. User_Notifications : ErrorConstants.Api[500]
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    private populateSaveUserSettings(userSettings: Array<any>): any {
        const setting = {};
        for (let index = 0; index < userSettings.length; index++) {
            const element = userSettings[index];
            setting[element.name] = element.value;
        }
        return setting;
    }
}
