import { Injectable } from '@angular/core';
import { ApiDictionary, Resources, ErrorConstants, SuccessConstants } from '../../constants';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Response } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class RolePermissionService {
    constructor(private apiService: ApiService) { }

    getRolegroups(id: number): Observable<Object> {
        let rolePermissionUrl = ApiDictionary.getRoleGroups.url;
        rolePermissionUrl = Resources.getRoleGroups + rolePermissionUrl + '/' + id;
        const param = this.apiService.BindParamsToUrl(rolePermissionUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(param).subscribe((response: any) => {
                if (response instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[response.status]
                    };
                    observer.next(res);
                }
                if (response.RolePermissions && response.RolePermissions.length > 0) {
                    const successRes: Response = {
                        failure: false, success: true,
                        result: this.buildResponseModel(response.RolePermissions), error: null
                    };
                    observer.next(successRes);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: ErrorConstants.Api[error.status || error.Status || error.Code]
                };
                observer.next(res);
            });
        });
        return observable;
    }

    private buildResponseModel(result: Array<any>) {
        const permissions = [];
        for (let index = 0; index < result.length; index++) {
            const element = result[index];
            const subRolePermissions = [];
            for (let count = 0; count < element.SubRolePermissions.length; count++) {
                const subPermission = element.SubRolePermissions[count];
                subRolePermissions.push({
                    id: subPermission.Id, name: subPermission.Action,
                    status: subPermission.IsActive, parentId: element.Id
                });
            }
            permissions.push({ id: element.Id, name: element.Name, subPermissions: subRolePermissions });
        }
        return permissions;
    }

    saveOrUpdateRoleGroups(roleAccessSet: Array<any>, roleInfo: any) {
        const observable = new Observable((observer) => {
            if (roleAccessSet && roleAccessSet.length > 0) {
                const roleAccess: Array<any> = [];
                for (let index = 0; index < roleAccessSet.length; index++) {
                    roleAccess.push({ Id: roleAccessSet[index].id, IsActive: roleAccessSet[index].status });
                }
                const permissionReq = { Id: roleInfo.id, SavePermissionGps: roleAccess};
                let apiUrl = ApiDictionary.saveRoleGroups.url;
                apiUrl = Resources.saveRoleGroups + apiUrl;
                this.apiService.updateData(apiUrl, permissionReq).subscribe((response: any) => {
                    if (response instanceof HttpErrorResponse) {
                        const res: Response = {
                            failure: true, success: false,
                            error: response.status === 400 ? response.error : ErrorConstants.Api[response.status]
                        };
                        observer.next(res);
                    } else {
                        const res: Response = {
                            failure: false, success: true,
                            result: SuccessConstants.Messages.Permission_Group_Save_Success, error: null
                        };
                        observer.next(res);
                    }
                }, (error) => {
                    const res: Response = {
                        failure: true, success: false,
                        error: null
                    };
                    if (!error.Code && error.Code.indexOf('400') > -1) {
                        res.error = error.Message;
                    } else {
                        res.error = ErrorConstants.Api[error.Code || error.status || error.Status];
                    }
                    observer.next(res);
                });
            } else {
                const res: Response = {
                    result: null, failure: true,
                    success: false, error: 'Add atleast one permission group to save'
                };
                observer.next(res);
            }
        });
        return observable;
    }
}
