import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { Response, UserRequest, Profile, PortalUserRequest, PortalUserResponse, SavePortalUserRequest } from '../../models';
import { ErrorConstants, ApiDictionary, Resources, SuccessConstants } from '../../constants';
import { ApiService } from '../api.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class UserService {
    userName = '';
    constructor(private authService: AuthService, private apiService: ApiService) { }

    getPortalUser(userName: string): Observable<any> {
        let apiUrl = ApiDictionary.getPortalUserDetails.url;
        apiUrl = Resources.getPortalUserDetails + apiUrl;
        const observable = new Observable<any>((observer) => {
            const request: PortalUserRequest = {
                IsActive: false,
                MobileNo: '',
                Status: false,
                UserName: userName
            };
            this.apiService.postData(apiUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: result.PortalUsers.length > 0 ? this.buildGetProfile(result.PortalUsers[0]) : {}, error: null
                    };
                    observer.next(res);
                }
            }, (error: any) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    buildGetProfile(result: any): any {
        if (result) {
            const response: PortalUserResponse = {
                userName: result.UserName,
                enabled: result.Enabled,
                email: '',
                birthDate: new Date(),
                firstName: '',
                is_master: false,
                labeledName: result.LabeledName,
                lastName: result.LastName,
                phone_number: '',
                email_verified: false,
                phone_number_verified: false,
                role: '',
                sub: '',
                tier: ''
            };
            for (const key in result.UserFields) {
                if (result.UserFields.hasOwnProperty(key)) {
                    const fieldValue = result.UserFields[key] as string;
                    switch (key) {
                        case 'given_name':
                            response.firstName = fieldValue;
                            break;
                        case 'family_name':
                            response.lastName = fieldValue;
                            break;
                        case 'birthdate':
                            response.birthDate = fieldValue.indexOf('T') > -1
                                ? new Date(fieldValue.substring(0, fieldValue.indexOf('T')))
                                : new Date(fieldValue);
                            break;
                        case 'custom:Role':
                            response.role = fieldValue;
                            break;
                        case 'custom:Tier':
                            response.tier = fieldValue;
                            break;
                        case 'phone_number':
                            response.phone_number = fieldValue.indexOf('+91') > -1 ? fieldValue.substring(3) : fieldValue;
                            break;
                        case 'phone_number_verified':
                            response.phone_number_verified = fieldValue.toLowerCase() === 'true' ? true : false;
                            break;
                        case 'email':
                            response.email = fieldValue;
                            break;
                        case 'email_verified':
                            response.email_verified = fieldValue.toLowerCase() === 'true' ? true : false;
                            break;
                        case 'sub':
                            response.sub = fieldValue;
                            break;
                        case 'custom:Is_master':
                            response.is_master = fieldValue.toLowerCase() === 'true' ? true : false;
                            break;
                        default:
                            break;
                    }
                }
            }
            return response;
        }
        return {};
    }

    createUser(userDetails: any): Observable<any> {
        let apiUrl = ApiDictionary.savePortalUserDetails.url;
        apiUrl = Resources.savePortalUserDetails + apiUrl;
        const request: SavePortalUserRequest = {
            OldUserName: userDetails.userName,
            UserFields: {},
            UserName: userDetails.userName.trim(),
            IsUserNameChanged: false,
            IsMobileNoChanged: false,
            Sub: '',
            UID: userDetails.password ? this.setUID(userDetails.password) : null,
            CartId: userDetails.cartId || null,
            CreatedUserName: userDetails.createdUserName || null
        };
        request.UserFields['phone_number'] = '+91' + userDetails.phone_number;
        request.UserFields['phone_number_verified'] = false;
        if (userDetails.email) {
            request.UserFields['email'] = userDetails.email;
        }
        if (userDetails.tier) {
            request.UserFields['custom:Tier'] = userDetails.tier;
        }
        if (userDetails.role) {
            request.UserFields['custom:Role'] = userDetails.role;
        }
        if (userDetails.birthDate) {
            request.UserFields['birthdate'] = (userDetails.birthDate as Date).toDateString();
        }
        if (userDetails.firstName) {
            request.UserFields['given_name'] = userDetails.firstName;
        }
        if (userDetails.lastName) {
            request.UserFields['family_name'] = userDetails.lastName;
        }
        if (userDetails.is_master === false || userDetails.is_master) {
            request.UserFields['custom:Is_master'] = userDetails.is_master;
        }
        if (userDetails.email) {
            request.UserFields['email_verified'] = false;
        }
        const promise = new Observable<any>((observer) => {
            this.apiService.postData(apiUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error: any) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return promise;
    }

    updatePortalUser(userDetails: any, cognitoUserInfo: PortalUserResponse): Observable<any> {
        let apiUrl = ApiDictionary.updatePortalUserDetails.url;
        apiUrl = Resources.updatePortalUserDetails + apiUrl;
        const request: SavePortalUserRequest = {
            OldUserName: cognitoUserInfo.userName,
            UserFields: {},
            UserName: userDetails.userName.trim(),
            Sub: cognitoUserInfo.sub,
            IsUserNameChanged: userDetails.userName.trim() !== cognitoUserInfo.userName,
            IsMobileNoChanged: userDetails.phone_number !== cognitoUserInfo.phone_number,
            UID: '',
            CartId: '',
            CreatedUserName: null
        };
        request.UserFields['phone_number'] = '+91' + userDetails.phone_number;
        request.UserFields['phone_number_verified'] = request.IsMobileNoChanged ? false : true;
        if (userDetails.email) {
            request.UserFields['email'] = userDetails.email;
        }
        if (userDetails.tier) {
            request.UserFields['custom:Tier'] = userDetails.tier;
        }
        if (userDetails.role || cognitoUserInfo.role) {
            request.UserFields['custom:Role'] = userDetails.role || cognitoUserInfo.role;
        }
        if (userDetails.birthDate) {
            request.UserFields['birthdate'] = (userDetails.birthDate as Date).toDateString();
        }
        if (userDetails.firstName) {
            request.UserFields['given_name'] = userDetails.firstName;
        }
        if (userDetails.lastName) {
            request.UserFields['family_name'] = userDetails.lastName;
        }
        if (userDetails.is_master === false || userDetails.is_master) {
            request.UserFields['custom:Is_master'] = userDetails.is_master;
        }
        if (userDetails.email) {
            request.UserFields['email_verified'] = cognitoUserInfo.email &&
                userDetails.email === cognitoUserInfo.email ? cognitoUserInfo.email_verified : false;
        }
        const observable = new Observable<any>((observer) => {
            this.apiService.updateData(apiUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: result.BooleanValue ?
                            userDetails.userName + SuccessConstants.Messages.Save_Success : ErrorConstants.Api[500]
                    };
                    observer.next(res);
                }
            }, (error: any) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    keyFormate(key: string): string {
        if (key.indexOf('_') === -1) {
            return key[0].toUpperCase() + key.slice(1);
        } else {
            return key;
        }
    }

    setUID(pwd: string): string {
        return btoa(pwd);
    }

    getUserList(searchObj: any): Observable<any> {
        let apiUrl = ApiDictionary.getUserDetails.url;
        apiUrl = Resources.getUserDetails + apiUrl;
        const observable = new Observable((observer) => {
            const request = {
                UserName: searchObj.userName, IsActive: searchObj.status,
                PageIndex: searchObj.pageIndex, PageSize: searchObj.pageSize,
                SortBy: searchObj.sortBy, SortDirection: searchObj.sortDirection
            };
            this.apiService.postData(apiUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: {
                            users: this.populateUserData(result.UserResponses),
                            total: result.TotalCount
                        }, error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateUserData(users: Array<any>): Array<any> {
        const userData = [];
        for (let index = 0; index < users.length; index++) {
            const user = {
                id: users[index].Id, userName: users[index].UserName,
                firstName: users[index].FirstName, lastName: users[index].LastName,
                isActive: users[index].IsActive, primaryRole: users[index].PrimaryRole
            };
            userData.push(user);
        }
        return userData;
    }

    saveUserDetails(profile: Profile, uid: string, lat: number, long: number): Observable<any> {
        let apiUrl = ApiDictionary.saveUserDetails.url;
        apiUrl = Resources.saveUserDetails + apiUrl;
        const userData: UserRequest = {
            FirstName: profile.given_name,
            UID: uid,
            Latitude: lat,
            Longitude: long,
            IsActive: true,
            LastName: profile.family_name,
            LoginType: 'Application',
            MobileId: '+91' + profile.phone_number,
            UserName: profile.preferred_username,
            Role: profile.role,
            Sub: profile.sub,
            Email: profile.email,
            PhoneNumberVerified: profile.phone_number_verified,
            EmailVerified: profile.email_verified
        };
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, userData).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    deleteUserDetails(userName: string, status: boolean): Observable<any> {
        let apiUrl = ApiDictionary.deletePortalUserDetails.url;
        apiUrl = Resources.deletePortalUserDetails + apiUrl + '/' + userName + '/' + (!status);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: userName +
                            (!status ? SuccessConstants.Messages.Activate_Success : SuccessConstants.Messages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    deleteUserSession(userName: string): Observable<any> {
        const apiUrl = Resources.deleteUserSession + ApiDictionary.deleteUserSession.url + '/' + userName;
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: true
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
}

