import { Component } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { MatDialogRef } from '@angular/material';
@Component({
    selector: 'app-session-popup',
    templateUrl: 'sessionPopup.component.html'
})
export class SessionPopupComponent {
    interval: number;
    constructor(private idle: Idle, public dialogRef: MatDialogRef<SessionPopupComponent>) {
        this.idle.onTimeoutWarning.subscribe((interval) => {
            this.interval = interval;
            if (this.interval === 1) {
              this.close(false);
            }
        });
    }
    close(response: any) {
        this.dialogRef.close(response);
    }
}
