import { Component, Inject } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Response } from '../../../models';
import { ModalPopupService, LoaderService, AuthService } from '../../../providers';
import { AlertComponent } from '../alert/alert.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-otp',
    templateUrl: 'otp.component.html',
    styleUrls: ['./otp.component.css']
})
export class OtpComponent {
    otpForm: FormGroup;
    userName = '';
    trials = 0;
    errMsg = '';
    constructor(private fb: FormBuilder, private authService: AuthService,
        private modalPopupService: ModalPopupService, public dialogRef: MatDialogRef<OtpComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private loaderService: LoaderService) {
        this.userName = data.userName;
        this.otpForm = this.fb.group({
            userName: new FormControl({ value: data.userName, disabled: true }),
            otp: new FormControl('', Validators.compose([Validators.required, Validators.minLength(4)]))
        });
    }

    validateOtp(): void {
        if (this.otpForm.valid) {
            ++this.trials;
            this.errMsg = '';
            if (this.trials <= 3) {
                const dialogRef = this.loaderService.start();
                this.authService.confirmMobileDevice(this.userName, this.otpForm.controls['otp'].value).subscribe((response: Response) => {
                    this.loaderService.close(dialogRef);
                    if (response.error) {
                        this.errMsg = response.error;
                    } else {
                        this.modalPopupService.openPopup(AlertComponent, { message: response.result }).afterClosed().subscribe((res) => {
                            this.dialogRef.close(true);
                        });
                    }
                });
            } else {
                this.dialogRef.close(false);
            }
        }
    }
}
