import { Injectable } from '@angular/core';
import { ApiDictionary, Resources, ErrorConstants } from '../../constants';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Response } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class NotificationService {
    constructor(private apiService: ApiService) { }

    getNotification(searchObj: any): Observable<any> {
        let notificationurl = ApiDictionary.getNotificationSearch.url;
        notificationurl = Resources.getNotificationSearch + notificationurl;
        const observable = new Observable((observer) => {
            const request = {
                NotificationType: searchObj.notificationType, PriyorityType: searchObj.priyorityType,
                EventType: searchObj.eventType, SentOn: searchObj.sentOn.toDateString(),
                User: searchObj.user, Store: searchObj.store,
                SendManually: searchObj.sendManually,
                IsActive: searchObj.isActive
                //  RoleId: searchObj.roleId,
            };
            this.apiService.postData(notificationurl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateNotificationList(result.Notifications), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateNotificationList(Notifications: any): any {
        const notificationList = [];
        for (let index = 0; index < Notifications.length; index++) {
            const notification = {
                id: Notifications[index].NotificationId,
                notificationType: Notifications[index].NotificationType,
                notificationTypeName: Notifications[index].NotificationTypeName,
                priyorityId: Notifications[index].PriyorityId,
                PriyorityName: Notifications[index].PriyorityName,
                eventId: Notifications[index].EventId,
                EventName: Notifications[index].EventName,
                store: Notifications[index].StoreId,
                storeName: Notifications[index].StoreName,
                user: Notifications[index].UserId,
                userName: Notifications[index].UserName,
                sendManually: Notifications[index].SendManually,
                sentOn: Notifications[index].SentOnUtc ? new Date(Notifications[index].SentOnUtc) : null,
            };
            notificationList.push(notification);
        }
        return notificationList;
    }

}
