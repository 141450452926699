import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Response } from '../../models';
import { Router } from '@angular/router';
import { AuthService, LoaderService, PermissionService, ProfileService } from '../../providers';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalPopupService } from '../../providers';
import { AlertComponent } from '../../utils';


@Component({
    selector: 'app-set-password',
    templateUrl: './set-password.component.html',
    styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent {
    setPasswordForm: FormGroup;
    verifyForm: FormGroup;
    errorMsg = '';
    userName = '';
    message = '';
    isResetForm: boolean;
    isForgotForm: boolean;
    isChangeForm: boolean;
    showOTPForm = false;
    formTitle = '';
    constructor(private authService: AuthService,
        private profileService: ProfileService,
        private router: Router,
        private loaderService: LoaderService,
        private permissionService: PermissionService,
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<SetPasswordComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private modalPopupService: ModalPopupService) {
        this.userName = data.userName;
        this.message = data.message;
        this.setPasswordForm = new FormGroup({
            password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8),
            Validators.pattern('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,12}$')])),
            confirmPassword: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8),
            Validators.pattern('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,12}$')]))
        });

        switch (data.formType) {
            case 'ForgotPassword': {
                this.formTitle = 'Forgot Password';
                this.setPasswordForm.addControl('userName', new FormControl('', Validators.required));
                this.isForgotForm = true;
                break;
            }
            case 'ResetPassword':
                {
                    this.formTitle = 'Reset Password';
                    this.setPasswordForm.addControl('userName', new FormControl({ value: this.userName, disabled: true }));
                    this.isResetForm = true;
                    break;
                }
            case 'ChangePassword': {
                this.formTitle = 'Change Your Password';
                this.setPasswordForm.addControl('userName', new FormControl({ value: this.userName, disabled: true }));
                this.setPasswordForm.addControl('oldPassword', new FormControl('', Validators.required));
                this.isChangeForm = true;
                break;
            }
        }
    }

    submit(): void {
        if (this.setPasswordForm.valid) {
            if (this.isResetForm) {
                this.resetPassword();
            } else if (this.isForgotForm) {
                this.forgetPassword();
            } else if (this.isChangeForm) {
                this.changePassword();
            }
        } else if (this.setPasswordForm.disabled && this.isForgotForm) {
            this.forgetPassword();
        }
    }

    resetPassword(): void {
        const password = this.setPasswordForm.get('password').value;
        const dialogRef = this.loaderService.start();
        this.authService.resetPassword(password, this.userName)
            .subscribe((response: Response) => {
                this.loaderService.close(dialogRef);
                this.errorMsg = '';
                if (response.success) {
                    this.modalPopupService.openPopup(AlertComponent, { message: response.result }).afterClosed()
                        .subscribe((res: any) => {
                            this.dialogRef.close(true);
                        });
                } else if (response.failure && (typeof response.error === 'string')) {
                    this.errorMsg = response.error;
                } else {
                    console.log(response.error);
                    this.modalPopupService.openPopup(AlertComponent, { message: 'Something Wrong! Please Try Again' });
                }
            });

    }

    forgetPassword(): void {
        if (this.isForgotForm && !this.showOTPForm) {
            this.userName = this.setPasswordForm.get('userName').value;
            this.forgotPasswordInitiate();
        } else if (this.showOTPForm && this.verifyForm.valid) {
            const User = this.setPasswordForm.get('userName').value;
            const password = this.setPasswordForm.get('password').value;
            const verifyCode = this.verifyForm.get('verifyCode').value;
            const dialogRef = this.loaderService.start();
            this.authService.forgotPasswordConfirmation(verifyCode, password, User).subscribe(response => {
                this.loaderService.close(dialogRef);
                this.errorMsg = '';
                if (response.success) {
                    this.modalPopupService.openPopup(AlertComponent, { message: response.result }).afterClosed()
                    .subscribe((res: any) => {
                        this.dialogRef.close(true);
                    });
                    this.router.navigate(['./login']);
                } else if (response.failure && (typeof response.error === 'string')) {
                    this.errorMsg = response.error;
                } else {
                    console.log(response.error);
                    alert('Something Wrong! Please Try Again');
                }
            });
        }
    }

    forgotPasswordInitiate() {
        const dialogRef = this.loaderService.start();
        this.authService.forgotPasswordInitiation(this.userName).subscribe(response => {
            this.loaderService.close(dialogRef);
            this.errorMsg = '';
            if (response.success) {
                this.errorMsg = response.result;
                this.verifyForm = this.fb.group({
                    verifyCode: new FormControl('', Validators.compose([Validators.required, Validators.pattern('^([0-9]).{0,6}$')]))
                });
                this.showOTPForm = true;
                this.setPasswordForm.disable();
            } else if (response.failure && (typeof response.error === 'string')) {
                this.errorMsg = response.error;
            } else {
                console.log(response.error);
                alert('Something Wrong! Please Try Again');
            }
        });
    }

    changePassword(): void {
        const oldPassword = this.setPasswordForm.get('oldPassword').value;
        const password = this.setPasswordForm.get('password').value;
        const dialogRef = this.loaderService.start();
        this.authService.changePassword(this.userName, oldPassword, password).subscribe(response => {
            this.loaderService.close(dialogRef);
            this.errorMsg = '';
            if (response.success) {
                this.modalPopupService.openPopup(AlertComponent, { message: response.result }).afterClosed()
                .subscribe((res: any) => {
                    this.dialogRef.close(true);
                });
                 this.router.navigate(['./login']);
            } else if (response.failure && (typeof response.error === 'string')) {
                this.errorMsg = response.error;
            } else {
                console.log(response.error);
                alert('Something Wrong! Please Try Again');
            }
        });
    }

    reSendCode() {
        if (this.showOTPForm) {
            this.forgotPasswordInitiate();
        }
    }

}
// function checkIfMatchingPasswords(group: FormGroup): any {
//     return (group.get("password").value === group.get("confirmPassword").value) ? null : { 'notEquivalent': true };
// }
