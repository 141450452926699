const OperatorPermissions = [
    {
        name: 'Profile', sortBy: 0, canCreate: false, canEdit: true, canDelete: false,
        canView: true, canMenuItem: false, src: '', navigatePage: 'ProfilePage'
    },
    {
        name: 'Product List', sortBy: 0, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: false, src: '', navigatePage: 'ProductListPage'
    },
    {
        name: 'Product Detail', sortBy: 0, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: false, src: '', navigatePage: 'ProductDetailPage'
    },
    {
        name: 'Home', sortBy: 1, canCreate: true, canEdit: true, canDelete: true,
        canView: true, canMenuItem: true, src: '', navigatePage: 'UsersPage'
    },
    {
        name: 'Catalog', sortBy: 2, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: true, src: '', navigatePage: 'CatalogPage'
    },
    {
        name: 'Grocery', sortBy: 3, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: true, src: '../assets/icon/groceries.svg', navigatePage: 'ProductListPage/1'
    },
    {
        name: 'Confectionary', sortBy: 4, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: true, src: '../assets/icon/grocery.svg', navigatePage: 'ProductListPage/2'
    },
    {
        name: 'Households', sortBy: 5, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: true, src: '../assets/icon/shop.svg', navigatePage: 'ProductListPage/3'
    },
    {
        name: 'Personal Care', sortBy: 6, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: true, src: '../assets/icon/shop.svg', navigatePage: 'ProductListPage/4'
    },
    {
        name: 'Notifications', sortBy: 7, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: true, src: '../assets/icon/notification.svg', navigatePage: 'NotificationsPage'
    },
    {
        name: 'Settings', sortBy: 8, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: true, src: '../assets/icon/settings.svg', navigatePage: 'SettingsPage'
    },
    {
        name: 'Logout', sortBy: 9, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: true, src: '../assets/icon/logout.svg', navigatePage: 'LandingPage'
    },
    {
        name: 'User', sortBy: 10, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: true, src: '', navigatePage: 'UserPage'
    }];

const UserPermissions = [
    {
        name: 'Profile', sortBy: 0, canCreate: false, canEdit: true, canDelete: false,
        canView: true, canMenuItem: false, src: '', navigatePage: 'ProfilePage'
    },
    {
        name: 'Product List', sortBy: 0, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: false, src: '', navigatePage: 'ProductListPage'
    },
    {
        name: 'Product Detail', sortBy: 0, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: false, src: '', navigatePage: 'ProductDetailPage'
    },
    {
        name: 'Home', sortBy: 1, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: true, src: '', navigatePage: 'CatalogPage'
    },
    {
        name: 'Grocery', sortBy: 2, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: true, src: '../assets/icon/groceries.svg', navigatePage: 'ProductListPage/1'
    },
    {
        name: 'Confectionary', sortBy: 3, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: true, src: '../assets/icon/grocery.svg', navigatePage: 'ProductListPage/2'
    },
    {
        name: 'Households', sortBy: 4, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: true, src: '../assets/icon/shop.svg', navigatePage: 'ProductListPage/3'
    },
    {
        name: 'Personal Care', sortBy: 5, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: true, src: '../assets/icon/shop.svg', navigatePage: 'ProductListPage/4'
    },
    {
        name: 'Notifications', sortBy: 6, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: true, src: '../assets/icon/notification.svg', navigatePage: 'NotificationsPage'
    },
    {
        name: 'Settings', sortBy: 7, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: true, src: '../assets/icon/settings.svg', navigatePage: 'SettingsPage'
    },
    {
        name: 'Master Cart', sortBy: 8, canCreate: true, canEdit: true, canDelete: true,
        canView: true, canMenuItem: true, src: '../assets/icon/shopCart.svg', navigatePage: 'MasterCartPage'
    },
    {
        name: 'Logout', sortBy: 9, canCreate: false, canEdit: false, canDelete: false,
        canView: true, canMenuItem: true, src: '../assets/icon/logout.svg', navigatePage: 'LandingPage'
    }];

export const AppPermissiosSet = {
    OperatorPermissionset: OperatorPermissions,
    UserPermissionset: UserPermissions
};
