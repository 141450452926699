import { Injectable } from '@angular/core';
import { ApiDictionary, Resources, ErrorConstants, SuccessConstants } from '../../constants';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Response, Media } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class MediaService {
    constructor(private apiService: ApiService) { }

    saveMedia(media: Media): Observable<any> {
        let apiUrl = ApiDictionary.saveMedia.url;
        apiUrl = Resources.saveMedia + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, media).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    searchMedia(searchObj: any): Observable<any> {
        let apiUrl = ApiDictionary.getMediaSearch.url;
        apiUrl = Resources.getMediaSearch + apiUrl;
        const observable = new Observable((observer) => {
            const request = {
                ImageText: searchObj.name, IsActive: searchObj.status, MediaType: searchObj.mediaType,
                ExpiryDate: searchObj.expiryDate.toDateString(),
                PageIndex: searchObj.pageIndex, PageSize: searchObj.pageSize, SortBy: searchObj.sortBy,
                SortDirection: searchObj.sortDirection
            };
            this.apiService.postData(apiUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: { 'mediaData': this.populateMediaList(result.Medias), 'totalCount': result.TotalCount }, error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateMediaList(medias) {
        const mediaList = [];
        for (let index = 0; index < medias.length; index++) {
            const media = {
                id: medias[index].Id,
                name: medias[index].ImageText,
                mediaTypeId: medias[index].MediaTypeId,
                startDate: medias[index].StartDate ?
                    (medias[index].StartDate.indexOf('T') > 0
                        ? new Date(medias[index].StartDate.substring(0, medias[index].StartDate.indexOf('T')))
                        : new Date(medias[index].StartDate))
                    : null,
                endDate: medias[index].EndDate ?
                    (medias[index].EndDate.indexOf('T') > 0
                        ? new Date(medias[index].EndDate.substring(0, medias[index].EndDate.indexOf('T')))
                        : new Date(medias[index].EndDate)) : null,
                isActive: medias[index].IsActive,
                logo: medias[index].Image ? medias[index].Image : '',
                thumbnail: medias[index].Thumbnail ? medias[index].Thumbnail : '',
                isHeroImage: medias[index].IsHeroImage
            };
            mediaList.push(media);
        }
        return mediaList;
    }

    updateMedia(media: Media): Observable<any> {
        let apiUrl = ApiDictionary.updateMedia.url;
        apiUrl = Resources.updateMedia + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.updateData(apiUrl, media).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: media.ImageText + SuccessConstants.Messages.Save_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    deleteMedia(id: number, status: boolean, name: string): Observable<any> {
        let apiUrl = ApiDictionary.deleteMedia.url;
        apiUrl = Resources.deleteMedia + apiUrl + '/' + id + '/' + (!status);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: name + (!status ? SuccessConstants.Messages.Activate_Success : SuccessConstants.Messages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
    searchMediaTypes(): Observable<any> {
        let typeUrl = ApiDictionary.getMediaTypeSearch.url;
        typeUrl = Resources.getMediaTypeSearch + typeUrl;
        const options = this.apiService.BindParamsToUrl(typeUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(options).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateMediaTypes(result.Types), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    private populateMediaTypes(types: any): any {
        const typeList = [];
        for (let index = 0; index < types.length; index++) {
            const store = {
                name : types[index].Key,
                id : types[index].Value,
            };
            typeList.push(store);
        }
        return typeList;
    }
}
