import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    NbThemeModule, NbSidebarModule, NbLayoutModule, NbActionsModule,
    NbMenuModule, NbContextMenuModule, NbUserModule, NbSpinnerModule
} from '@nebular/theme';
import {
    MatButtonModule, MatFormFieldModule,
    MatCardModule,
    MatInputModule, MatDialogModule,
    MatListModule, MatTableModule,
    MatPaginatorModule, MatIconModule,
    MatCheckboxModule,
    MatSelectModule, MatOptionModule
} from '@angular/material';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoginComponent } from './login/login.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized';
import { UtilModule } from '../utils';

@NgModule({
    declarations: [FooterComponent, HeaderComponent,
        LandingPageComponent, LoginComponent,
        SetPasswordComponent, UnauthorizedComponent],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        NoopAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        NbThemeModule.forRoot({ name: 'default' }),
        NbSidebarModule,
        NbLayoutModule,
        NbActionsModule,
        NbMenuModule.forRoot(),
        NbContextMenuModule,
        NbUserModule,
        NbSpinnerModule,
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatCardModule,
        MatListModule,
        MatTableModule,
        MatIconModule,
        MatCheckboxModule,
        MatSelectModule,
        MatOptionModule,
        MatPaginatorModule,
        UtilModule
    ],
    exports: [FooterComponent, HeaderComponent,
        LandingPageComponent, LoginComponent,
        SetPasswordComponent, UnauthorizedComponent]
})
export class CoreModule { }
