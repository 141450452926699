const SuccessMessageConstants = {
    'Reset_Success_Message': 'Password reset is successfully completed',
    'Change_Password_Success_Message': 'Your password is changed successfully',
    'Forgot_Password_Success_Message': 'Your password is changed successfully',
    'Signup_Suceess_Message': 'Your registration is successfully completed, login to your account',
    'Forgot_Password_Initiated_Message': 'Verification code sent to your registered mobile number.',
    'Profile_Update': 'Profile updated successfully',
    'Save_Success': ' is saved successfully',
    'Update_Success': ' is updated successfully',
    'Activate_Success': ' is activated successfully',
    'Deactivate_Success': ' is deactivated successfully',
    'Permission_Group_Save_Success': 'Permission group saved successfully',
    'Delete_Success': ' is deleted successfully',
    'User_Role_Success_Message': 'roles added successfully to ',
    'Mobile_Verification_Message': 'Enter verification code sent to your mobile number',
    'Mobile_Verification_Success_Message': 'Mobile verification is done',
    'User_Notifications': 'User Preferences Saved Successfully'
};
export const SuccessConstants = {
    Messages: SuccessMessageConstants
};
