import { environment } from 'src/environments/environment';

export const ApiResources = {
    'getUserDetails': `${environment.apiUrl}/v3/`,
    'getProducts': `${environment.apiUrl}/v3/admin/`,
    'getUserProducts': `${environment.apiUrl}/v3/user/`,
    'getProductNames': `${environment.apiUrl}/v3/`,
    'getProductImagesById': `${environment.apiUrl}/v3/`,
    'saveProduct': `${environment.apiUrl}/v3/`,
    'updateProduct': `${environment.apiUrl}/v3/`,
    'deleteProduct': `${environment.apiUrl}/v3/`,
    'getProductDetails': `${environment.apiUrl}/v3/`,
    'getPermissionSet': `${environment.apiUrl}/v3/`,
    'getRoles': `${environment.apiUrl}/v3/`,
    'saveRole': `${environment.apiUrl}/v3/`,
    'updateRole': `${environment.apiUrl}/v3/`,
    'deleteRole': `${environment.apiUrl}/v3/`,
    'getRoleGroups': `${environment.apiUrl}/v3/`,
    'saveRoleGroups': `${environment.apiUrl}/v3/`,
    'getPermissions': `${environment.apiUrl}/v3/`,
    'getSortByPermissions': `${environment.apiUrl}/v3/`,
    'getSortbyPermissionStatus': `${environment.apiUrl}/v3/`,
    'savePermission': `${environment.apiUrl}/v3/`,
    'updatePermission': `${environment.apiUrl}/v3/`,
    'deletePermission': `${environment.apiUrl}/v3/`,
    'saveCatalog': `${environment.apiUrl}/v3/`,
    'getCatalogSearch': `${environment.apiUrl}/v3/`,
    'getUserCatalogSearch': `${environment.apiUrl}/v3/`,
    'deleteCatalog': `${environment.apiUrl}/v3/`,
    'updateCatalog': `${environment.apiUrl}/v3/`,
    'getImage': `${environment.apiUrl}/v3/`,
    'getThumbnail': `${environment.apiUrl}/v3/`,
    'getBrandSearch': `${environment.apiUrl}/v3/`,
    'saveBrand': `${environment.apiUrl}/v3/`,
    'updateBrand': `${environment.apiUrl}/v3/`,
    'deleteBrand': `${environment.apiUrl}/v3/`,
    'getCategorySearch': `${environment.apiUrl}/v3/`,
    'saveCategory': `${environment.apiUrl}/v3/`,
    'updateCategory': `${environment.apiUrl}/v3/`,
    'deleteCategory': `${environment.apiUrl}/v3/`,
    'getMediaSearch': `${environment.apiUrl}/v3/`,
    'saveMedia': `${environment.apiUrl}/v3/`,
    'updateMedia': `${environment.apiUrl}/v3/`,
    'deleteMedia': `${environment.apiUrl}/v3/`,
    'getProductOptionSearch': `${environment.apiUrl}/v3/`,
    'saveProductOption': `${environment.apiUrl}/v3/`,
    'updateProductOption': `${environment.apiUrl}/v3/`,
    'deleteProductOption': `${environment.apiUrl}/v3/`,
    'getUserRoles': `${environment.apiUrl}/v3/`,
    'saveUserRoles': `${environment.apiUrl}/v3/`,
    'updateUserRoles': `${environment.apiUrl}/v3/`,
    'saveUserDetails': `${environment.apiUrl}/v3/`,
    'getPortalUserDetails': `${environment.apiUrl}/v3/admin/`,
    'savePortalUserDetails': `${environment.apiUrl}/v3/admin/`,
    'updatePortalUserDetails': `${environment.apiUrl}/v3/admin/`,
    'deletePortalUserDetails': `${environment.apiUrl}/v3/admin/`,
    'getUserSettings': `${environment.apiUrl}/v3/`,
    'saveUserSettings': `${environment.apiUrl}/v3/`,
    'saveMasterSettings': `${environment.apiUrl}/v3/`,
    'saveNotificationTemplate': `${environment.apiUrl}/v3/`,
    'updateNotificationTemplate': `${environment.apiUrl}/v3/`,
    'deleteNotificationTemplate': `${environment.apiUrl}/v3/`,
    'getNotificationTemplateSearch': `${environment.apiUrl}/v3/`,
    'getNotificationPlaceHolders': `${environment.apiUrl}/v3/`,
    'getSubscription': `${environment.apiUrl}/v3/`,
    'saveSubscription': `${environment.apiUrl}/v3/`,
    'updateSubscription': `${environment.apiUrl}/v3/`,
    'deleteSubscription': `${environment.apiUrl}/v3/`,
    'savePromotion': `${environment.apiUrl}/v3/`,
    'getPromotionSearch': `${environment.apiUrl}/v3/`,
    'updatePromotion': `${environment.apiUrl}/v3/`,
    'deletePromotion': `${environment.apiUrl}/v3/`,
    'getPromotionDetails': `${environment.apiUrl}/v3/`,
    'getstorebysearchcriteria': `${environment.apiUrl}/v3/`,
    'saveAnnouncement': `${environment.apiUrl}/v3/`,
    'getAnnouncementSearch': `${environment.apiUrl}/v3/`,
    'updateAnnouncement': `${environment.apiUrl}/v3/`,
    'getPromotionTypeSearch': `${environment.apiUrl}/v3/`,
    'getNotificationTypeSearch': `${environment.apiUrl}/v3/`,
    'getEventTypeSearch': `${environment.apiUrl}/v3/`,
    'getPriorityTypeSearch': `${environment.apiUrl}/v3/`,
    'getUserSearch': `${environment.apiUrl}/v3/`,
    'saveOptinCart': `${environment.apiUrl}/v3/`,
    'getOptinCartDetails': `${environment.apiUrl}/v3/`,
    'getOptinCartSearch': `${environment.apiUrl}/v3/`,
    'updateOptinCart': `${environment.apiUrl}/v3/`,
    'masterSettings': `${environment.apiUrl}/v3/`,
    'getMasterSetting': `${environment.apiUrl}/v3/`,
    'saveFaq': `${environment.apiUrl}/v3/`,
    'getFaqSearch': `${environment.apiUrl}/v3/`,
    'updateFaq': `${environment.apiUrl}/v3/`,
    'deleteFaq': `${environment.apiUrl}/v3/`,
    'getCategoryTypeSearch': `${environment.apiUrl}/v3/`,
    'getCartDetailsbyUserName': `${environment.apiUrl}/v3/`,
    'saveCartItem': `${environment.apiUrl}/v3/`,
    'getCartItems': `${environment.apiUrl}/v3/`,
    'deleteCartItem': `${environment.apiUrl}/v3/`,
    'getCartMembers': `${environment.apiUrl}/v3/`,
    'updateCartName': `${environment.apiUrl}/v3/`,
    'getMediaTypeSearch': `${environment.apiUrl}/v3/`,
    'getCartItemFrequencyList': `${environment.apiUrl}/v3/`,
    'getPromotionBanners': `${environment.apiUrl}/v3/`,
    'getNotificationSearch': `${environment.apiUrl}/v3/`,
    'getUserFAQs': `${environment.apiUrl}/v3/`,
    'getOptinCartList': `${environment.apiUrl}/v3/`,
    'deleteUserSession': `${environment.apiUrl}/v3/`
};
