import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiDictionary, Resources, ErrorConstants, SuccessConstants } from '../../constants';
import {  Response, Promotion } from '../../models';

@Injectable()
export class PromotionService {
    constructor(private apiService: ApiService) { }

    savePromotion(promotion: Promotion): Observable<any> {
        let apiUrl = ApiDictionary.savePromotion.url;
        apiUrl = Resources.savePromotion + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, promotion).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    searchPromotion(searchObj: any): Observable<any> {
        let apiUrl = ApiDictionary.getPromotionSearch.url;
        apiUrl = Resources.getPromotionSearch + apiUrl;
        const observable = new Observable((observer) => {
            const request = {
                PromotionName: searchObj.name, PromotionType: searchObj.mediaType, IsActive: searchObj.status,
                PageIndex: searchObj.pageIndex, PageSize: searchObj.pageSize, SortBy: searchObj.sortBy,
                SortDirection: searchObj.sortDirection
            };
            this.apiService.postData(apiUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                       error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: { 'promotionData': this.populatePromotionList(result.Promotions),
                        'totalCount': result.TotalCount }, error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populatePromotionList(promotions) {
        const promotionList = [];
        for (let index = 0; index < promotions.length; index++) {
            const promotion: any = {};
            promotion.id = promotions[index].Id,
            promotion.name = promotions[index].PromotionName,
            promotion.storeId = promotions[index].StoreID,
            promotion.type = promotions[index].PromotionType,
            promotion.longitude = promotions[index].Longitude,
            promotion.latitude = promotions[index].Latitude,
            promotion.radius = promotions[index].Radius,
            promotion.totalClicks = promotions[index].TotalClicks,
            promotion.totalViews = promotions[index].TotalViews,
            promotion.date = promotions[index].CreatedOn,
            promotion.updatedOn = promotions[index].UpdatedOn,
            promotion.isActive = promotions[index].IsActive,
            promotion.media = [];
            for (let count = 0; count < promotions[index].Media.length; count++) {
                promotion.media.push(Resources.getImage + promotions[index].Media[count]);
            }
            promotionList.push(promotion);
        }
        return promotionList;
    }

    updatePromotion(promotion: Promotion): Observable<any> {
        let apiUrl = ApiDictionary.updatePromotion.url;
        apiUrl = Resources.updatePromotion + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.updateData(apiUrl, promotion).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: promotion.PromotionName + SuccessConstants.Messages.Save_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    getpromotionById(id: number): Observable<any> {
        let apiUrl = ApiDictionary.getPromotionDetails.url;
        apiUrl = Resources.getPromotionDetails + apiUrl + '/' + id;
        const options = this.apiService.BindParamsToUrl(apiUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(options).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populatePromotionDetails(result), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    searchStore(searchObj: any): Observable<any> {
        let storeUrl = ApiDictionary.getStoresSearch.url;
        storeUrl = Resources.getstorebysearchcriteria + storeUrl;
        const observable = new Observable((observer) => {
            const request = { Name: searchObj.name, IsActive: searchObj.status };
            this.apiService.postData(storeUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateStoreList(result.Store), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateStoreList(stores: any): any {
        const storeList = [];
        for (let index = 0; index < stores.length; index++) {
            const store = {
                storeId : stores[index].StoreId,
                storeName : stores[index].StoreName,
                companyName : stores[index].CompanyName,
                companyAddress : stores[index].CompanyAddress,
                companyPhoneNumber : stores[index].CompanyPhoneNumber ,
                companyGST : stores[index].CompanyGST,
                latitude : stores[index].Latitude,
                logitude : stores[index].Logitude,
                isActive : stores[index].IsActive,
                updatedOn : stores[index].UpdatedOn
            };
            storeList.push(store);
        }
        return storeList;
    }

    private populatePromotionDetails(promotion: any) {
        const promotionInfo: any = {};
        promotionInfo.id = promotion.Id;
        promotionInfo.name = promotion.PromotionName;
        promotionInfo.storeId = promotion.StoreID;
        promotionInfo.type = promotion.PromotionType;
        promotionInfo.longitude = promotion.Longitude;
        promotionInfo.latitude = promotion.Latitude;
        promotionInfo.radius = promotion.Radius;
        promotionInfo.totalClicks = promotion.TotalClicks,
        promotionInfo.totalViews = promotion.TotalViews,
        promotionInfo.isActive = promotion.IsActive;
        promotionInfo.date = promotion.CreatedOn ?
        promotion.CreatedOn.indexOf('T') ? new Date(promotion.CreatedOn.substr(0, promotion.CreatedOn.indexOf('T')))
                : new Date(promotion.CreatedOn) : null;
        promotionInfo.medias = [];
        for (let index = 0; index < promotion.PromotionMedias.length; index++) {
            const element = promotion.PromotionMedias[index];
            promotionInfo.medias.push(element.MediaId);
        }
        return promotionInfo;
    }

    deletePromotion(id: number, status: boolean, name: string): Observable<any> {
        let apiUrl = ApiDictionary.deletePromotion.url;
        apiUrl = Resources.deletePromotion + apiUrl + '/' + id + '/' + (!status);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: name + (!status ? SuccessConstants.Messages.Activate_Success : SuccessConstants.Messages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    searchAppPromotionBanners(id: number): Observable<any> {
        let apiUrl = ApiDictionary.getPromotionBanners.url;
        apiUrl = Resources.getPromotionBanners + apiUrl;
        const observable = new Observable((observer) => {
            const request = { StoreId: id };
            this.apiService.postData(apiUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populatePromotionBanners(result.Banners), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populatePromotionBanners(banners: Array<any>): Array<any> {
        const bannerList = [];
        for (let index = 0; index < banners.length; index++) {
            const element = {
                id: banners[index].Id,
                image: banners[index].Image ? Resources.getImage + banners[index].Image : '',
                imageText: banners[index].ImageText
            };
            bannerList.push(element);
        }
        return bannerList;
    }
}
