import { Injectable } from '@angular/core';
import { ApiDictionary, Resources, ErrorConstants, SuccessConstants } from '../../constants';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Response } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationTemplates } from '../../models/notificationtemplate/notificationtemplate.model';

@Injectable()
export class NotificationTemplatesService {
    constructor(private apiService: ApiService) { }

    saveNotificationTemplate(notifications: NotificationTemplates): Observable<any> {
        let apiUrl = ApiDictionary.saveNotificationTemplate.url;
        apiUrl = Resources.saveNotificationTemplate + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, notifications).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    updateNotificationTemplate(notifications: NotificationTemplates): Observable<any> {
        let apiUrl = ApiDictionary.updateNotificationTemplate.url;
        apiUrl = Resources.updateNotificationTemplate + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.updateData(apiUrl, notifications).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: notifications.TemplateName + SuccessConstants.Messages.Save_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
    deleteNotificationTemplate(id: number, status: boolean, name: string): Observable<any> {
        let apiUrl = ApiDictionary.deleteNotificationTemplate.url;
        apiUrl = Resources.deleteNotificationTemplate + apiUrl + '/' + id + '/' + (!status);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: name + (!status ? SuccessConstants.Messages.Activate_Success : SuccessConstants.Messages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
    searchNotificationTemplate(searchObj: any): Observable<any> {
        let apiUrl = ApiDictionary.getNotificationTemplateSearch.url;
        apiUrl = Resources.getNotificationTemplateSearch + apiUrl;
        const observable = new Observable((observer) => {
            const request = { Name: searchObj.name, IsActive: searchObj.status };
            this.apiService.postData(apiUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateTemplateList(result.TemplateResponses), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    private populateTemplateList(notifications: any): any {
        const templateList = [];
        for (let index = 0; index < notifications.length; index++) {
            const template = {
                id: notifications[index].Id,
                templateName: notifications[index].Name,
                isActive: notifications[index].IsActive,
                emailContent: notifications[index].templateBody ? notifications[index].templateBody.EmailContent : '',
                pushNotificationContent: notifications[index].templateBody
                    ? notifications[index].templateBody.PushNotificationsContent : '',
                smsContent: notifications[index].templateBody ? notifications[index].templateBody.SMSContent : ''
            };
            templateList.push(template);
        }
        return templateList;
    }

    getNotificationPlaceHolders(): Observable<any> {
        let apiUrl = ApiDictionary.getNotificationPlaceHolders.url;
        apiUrl = Resources.getNotificationPlaceHolders + apiUrl;
        const options = this.apiService.BindParamsToUrl(apiUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(options).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateTemplatePlaceHolders(result.PlaceHolders), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateTemplatePlaceHolders(placeHolders: Array<any>): Array<any> {
        const placeHolderList = [];
        for (let index = 0; index < placeHolders.length; index++) {
            const element: any = {
                label: placeHolders[index].Label,
                value: placeHolders[index].Value
            };
            placeHolderList.push(element);
        }
        return placeHolderList;
    }
}
