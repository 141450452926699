import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiDictionary, Resources, ErrorConstants, SuccessConstants } from '../../constants';
import { ProductOption, Response } from '../../models';

@Injectable()
export class ProductOptionService {
    constructor(private apiService: ApiService) { }

    saveProductOption(productOption: ProductOption): Observable<any> {
        let apiUrl = ApiDictionary.saveProductOption.url;
        apiUrl = Resources.saveProductOption + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, productOption).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const response: Response = {
                        failure: true, result: null, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(response);
                } else {
                    const response: Response = {
                        error: null, failure: false, success: true, result: result.StringValue
                    };
                    observer.next(response);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    updateProductOption(productOption: ProductOption): Observable<any> {
        let apiUrl = ApiDictionary.updateProductOption.url;
        apiUrl = Resources.updateProductOption + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.updateData(apiUrl, productOption).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const response: Response = {
                        failure: true, result: null, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(response);
                } else {
                    const response: Response = {
                        error: null, failure: false, success: true, result: productOption.Name + SuccessConstants.Messages.Save_Success
                    };
                    observer.next(response);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    deleteProductOption(id: number, name: string): Observable<any> {
        let apiUrl = ApiDictionary.deleteProductOption.url;
        apiUrl = Resources.deleteProductOption + apiUrl + '/' + id;
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: name + SuccessConstants.Messages.Delete_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    searchProductOption(searchObj: any): Observable<any> {
        let apiUrl = ApiDictionary.getProductOptionSearch.url;
        apiUrl = Resources.getProductOptionSearch + apiUrl;
        const observable = new Observable((observer) => {
            const request = { Name: searchObj.name };
            this.apiService.postData(apiUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const response: Response = {
                        failure: true, result: null, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(response);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateOptionList(result.ProductOption), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateOptionList(options) {
        const optionList = [];
        if (options) {
            for (let index = 0; index < options.length; index++) {
                const option = {
                    id: options[index].Id,
                    name: options[index].Name,
                    values: this.populateOptionValuesList(options[index].ProductOptionValues || [])
                };
                optionList.push(option);
            }
        }
        return optionList;
    }

    private populateOptionValuesList(values) {
        const valueList = [];
        for (let index = 0; index < values.length; index++) {
            const value = {
               id: values[index].ValueId,
               valueName: values[index].ValueName
            };
            valueList.unshift(value);
        }
        return valueList;
    }
}
