import { Component, OnInit } from '@angular/core';
import { MasterSettingService, ModalPopupService } from '../../providers';
import { AlertComponent } from '../../utils';
import { SharedService, RoutingService } from '../../providers';
import { Response } from '../../models';
import { settings } from 'cluster';
@Component({
    selector: 'app-landing-page',
    templateUrl: 'landing-page.component.html',
    styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
    response: any;
    constructor(
        private masterSettingService: MasterSettingService, public sharedService: SharedService,
        public routingService: RoutingService, private modalPopupService: ModalPopupService
    ) { }
    ngOnInit(): void {
        setTimeout(() => {
            this.getSession();
        });
    }
    getSession(): void {
        this.masterSettingService.getMasterSetting('General Settings').subscribe((response: Response) => {
            if (response.failure) {
            console.log(response.error);
             } else if (response.success) {
                this.sharedService.generalSettings = response.result.masterGeneralSetting.portalSessionTimeout;
             }
        });
    }
}
