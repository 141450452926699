import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {
    transform(value: Array<any>, keys: string[], searchTerm: string) {
        return value.filter((item) => {
           return keys.some(key => item.hasOwnProperty(key) && item[key].indexOf(searchTerm));
        });
    }
}
