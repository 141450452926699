import { Injectable } from '@angular/core';
import { ApiDictionary, Resources, ErrorConstants, SuccessConstants } from '../../constants';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Response, Subscription } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class SubscriptionService {
    constructor(private apiService: ApiService) { }

    saveSubscription(subscription: Subscription): Observable<any> {
        let apiUrl = ApiDictionary.saveSubscription.url;
        apiUrl = Resources.saveSubscription + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, subscription).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    getSubscription(searchObj: any): Observable<any> {
        let subscriptionUrl = ApiDictionary.getSubscription.url;
        subscriptionUrl = Resources.getSubscription + subscriptionUrl;
        const observable = new Observable((observer) => {
            const request = { Name: searchObj.name, IsActive: searchObj.status, Price: searchObj.price };
            this.apiService.postData(subscriptionUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateSubscriptionList(result.SubScrptions), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateSubscriptionList(SubScrptions: any): any {
        const subscriptionList = [];
        for (let index = 0; index < SubScrptions.length; index++) {
            const subscription = {
                id: SubScrptions[index].Id,
                subscriptionName: SubScrptions[index].SubscriptionName,
                isActive: SubScrptions[index].IsActive,
                price: SubScrptions[index].Price,
                frequency: SubScrptions[index].Frequency,
                membersMin: SubScrptions[index].MembersMin,
                membersMax: SubScrptions[index].MembersMax,
                optinCartMax: SubScrptions[index].OptinCartMax,
                optinMartCartMax: SubScrptions[index].OptinMartCartMax,
                shopingCartMax: SubScrptions[index].ShopingCartMax

            };
            subscriptionList.push(subscription);
        }
        return subscriptionList;
    }

    updateSubscription(subscription: Subscription): Observable<any> {
        let apiUrl = ApiDictionary.updateSubscription.url;
        apiUrl = Resources.updateSubscription + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.updateData(apiUrl, subscription).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: subscription.SubscriptionName + SuccessConstants.Messages.Save_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    deleteSubscription(id: number, status: boolean, name: string): Observable<any> {
        let apiUrl = ApiDictionary.deleteSubscription.url;
        apiUrl = Resources.deleteSubscription + apiUrl + '/' + id + '/' + (!status);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: name + (!status ?
                            SuccessConstants.Messages.Activate_Success : SuccessConstants.Messages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
}
