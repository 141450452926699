import { CognitoUserPool} from 'amazon-cognito-identity-js';
const UserPool = {
    UserPoolId: 'us-east-1_vuV12vojN', // 'us-east-1_iSiRPCZpU',
    ClientId: '6ofgnujrpv59al1k3u1747b978',
    Paranoia: 7
};

export const CongitoUtility = {
    GetPoolInfo: () => {
      return new CognitoUserPool(UserPool);
    },
    GetConfigKey: () => {
        return 'cognito-idp.' + UserPool.UserPoolId.split('_')[0] + '.amazonaws.com' + '/' + UserPool.UserPoolId;
    },
    GetIdentityPoolId: () => {
        return 'us-east-1:5cc6db92-e638-45c6-a996-c055813768f4';
    },
    GetCognitoRegion: () => {
        return UserPool.UserPoolId.split('_')[0];
    }
};

