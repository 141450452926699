import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { OMGridComponent } from './components/om-grid/om-grid.component';
import { GridSortPipe } from './pipes';
import { FileUploadComponent } from './components/fileUpload/fileUpload.component';
import { DateComparisonValidator, EqualValidator, NumericDirective } from './directives';
import {
    MatCheckboxModule, MatSelectModule, MatOptionModule, ErrorStateMatcher,
    MatInputModule, MatListModule, MatDialogModule, MatFormFieldModule, MatButtonModule
} from '@angular/material';
import { OtpComponent } from './components/otp/otp.component';


export class ErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
      const isSubmitted = form && form.submitted;
      return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
  }

@NgModule({
    declarations: [GridSortPipe, OMGridComponent, FileUploadComponent, OtpComponent,
        DateComparisonValidator, EqualValidator, NumericDirective],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatSelectModule,
        MatOptionModule,
        MatInputModule,
        MatListModule,
        MatDialogModule,
        MatFormFieldModule,
        MatButtonModule
    ],
    exports: [OMGridComponent, FileUploadComponent, OtpComponent, DateComparisonValidator, EqualValidator, NumericDirective],
    entryComponents: [
        OtpComponent
    ]
})
export class UtilModule {

}
