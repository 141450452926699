import { Injectable } from '@angular/core';
import { ApiDictionary, Resources, ErrorConstants, SuccessConstants } from '../../constants';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Response, UserRoles } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class UserRoleService {
    constructor(private apiService: ApiService) { }
    getRoleGroups(id: number): Observable<Object> {
        let apiUrl = ApiDictionary.getUserRoles.url;
        apiUrl = Resources.getUserRoles + apiUrl + '/' + id;
        const param = this.apiService.BindParamsToUrl(apiUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(param).subscribe((response: any) => {
                if (response instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[response.status]
                    };
                    observer.next(res);
                }
                if (response.UserRoles && response.UserRoles.length >= 0) {
                    const successRes: Response = {
                        failure: false, success: true,
                        result: this.buildResponseModel(response.UserRoles), error: null
                    };
                    observer.next(successRes);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: ErrorConstants.Api[error.status || error.Status || error.Code]
                };
                observer.next(res);
            });
        });
        return observable;
    }

    getUserDetails(id: number): Observable<Object> {
        let apiUrl = ApiDictionary.getUserSearch.url;
        apiUrl = Resources.getUserSearch + apiUrl + '/' + id;
        const param = this.apiService.BindParamsToUrl(apiUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(param).subscribe((response: any) => {
                if (response instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[response.status]
                    };
                    observer.next(res);
                }
                if (response.UserRoles && response.UserRoles.length >= 0) {
                    const successRes: Response = {
                        failure: false, success: true,
                        result: this.userDetails(response.UserRoles), error: null
                    };
                    observer.next(successRes);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: ErrorConstants.Api[error.status || error.Status || error.Code]
                };
                observer.next(res);
            });
        });
        return observable;
    }
    private buildResponseModel(userRoles: Array<any>) {
        const userRoleList = [];
        for (let id = 0; id < userRoles.length; id++) {
            userRoleList.push({ roleId: userRoles[id].RoleId });
        }
        return userRoleList;
    }
    private userDetails(userRoles: Array<any>) {
        const userDetailList = [];
        for (let id = 0; id < userRoles.length; id++) {
            userDetailList.push({
                 roleId: userRoles[id].RoleId,
                 id:  userRoles[id].UserId,
                 name:  userRoles[id].UserName
                 });
        }
        return userDetailList;
    }
    saveUserRoles(userRoles: UserRoles, userName: string) {
        let apiUrl = ApiDictionary.saveUserRoles.url;
        apiUrl = Resources.saveUserRoles + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, userRoles).subscribe((response) => {
                if (response instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: response.status === 400 ? response.error : ErrorConstants.Api[response.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null, result:  SuccessConstants.Messages.User_Role_Success_Message + userName
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false,
                    error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.status || error.Status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
}




