export const ApiDictionary = {
   getUserDetails: {
      url: 'getuserdetails',
      params: null
   },
   getProducts: {
      url: 'getproducts',
      params: null
   },
   getUserProducts: {
      url: 'getproducts',
      params: null
   },
   getProductNames: {
      url: 'getproductnames',
      params: null
   },
   getProductImagesById: {
      url: 'getproductimagesbyproductid',
      params: { id: 0 }
   },
   saveProduct: {
      url: 'saveproduct',
      params: null
   },
   updateProduct: {
      url: 'updateproduct',
      params: null
   },
   deleteProduct: {
      url: 'deleteproduct',
      params: { id: 0, status: false }
   },
   getProductDetails: {
      url: 'getproductdetails',
      params: { id: 0 }
   },
   getPermissionSet: {
      url: 'permissionset',
      params: { userId: '', isMobileRequest: false }
   },
   getRoles: {
      url: 'getrolesbysearchcriteria',
      params: null
   },
   saveRole: {
      url: 'addrole',
      params: null
   },
   updateRole: {
      url: 'updaterole',
      params: null
   },
   deleteRole: {
      url: 'deleterole',
      params: { id: 0, status: false }
   },
   getRoleGroups: {
      url: 'getrolegroups',
      params: null
   },
   saveRoleGroups: {
      url: 'saveupdaterolegroups',
      params: null
   },
   getPermissions: {
      url: 'getpermissions',
      params: null
   },
   getSortByPermissions: {
      url: 'getsortbypermissions',
      params: null
   },
   getSortbyPermissionStatus: {
      url: 'getsortbypermissionsbystatus',
      params: null
   },
   savePermission: {
      url: 'savepermission',
      params: null
   },
   updatePermission: {
      url: 'updatepermission',
      params: null
   },
   deletePermission: {
      url: 'deletepermission',
      params: null
   },
   saveCatalog: {
      url: 'savecatalog',
      params: null
   },
   getCatalogSearch: {
      url: 'getcatalogbysearchcriteria',
      params: null
   },
   getUserCatalogSearch: {
      url: 'getusercatalogbysearchcriteria',
      params: null
   },
   deleteCatalog: {
      url: 'deletecatalog',
      params: {
         id: 0, status: false
      }
   },
   updateCatalog: {
      url: 'updatecatalog',
      params: null
   },
   getBrandSearch: {
      url: 'getbrandbysearchcriteria',
      params: null
   },
   saveBrand: {
      url: 'savebrand',
      params: null
   },
   updateBrand: {
      url: 'updatebrand',
      params: null
   },
   deleteBrand: {
      url: 'deletebrand',
      params: {
         id: 0, status: false
      }
   },
   getCategorySearch: {
      url: 'getcategorybysearchcriteria',
      params: null
   },
   saveCategory: {
      url: 'savecategory',
      params: null
   },
   updateCategory: {
      url: 'updatecategory',
      params: null
   },
   deleteCategory: {
      url: 'deletecategory',
      params: {
         id: 0, status: false
      }
   },
   getMediaSearch: {
      url: 'getmediabysearchcriteria',
      params: null
   },
   saveMedia: {
      url: 'savemedia',
      params: null
   },
   updateMedia: {
      url: 'updatemedia',
      params: null
   },
   deleteMedia: {
      url: 'deletemedia',
      params: {
         id: '', status: false
      }
   },
   getProductOptionSearch: {
      url: 'getproductoptionbysearchcriteria',
      params: null
   },
   saveProductOption: {
      url: 'saveproductoption',
      params: null
   },
   updateProductOption: {
      url: 'updateproductoption',
      params: null
   },
   deleteProductOption: {
      url: 'deleteproductoption',
      params: { id: 0 }
   },
   getUserRoles: {
      url: 'getuserroles',
      params: {
         id: 0
      }
   },
   saveUserRoles: {
      url: 'adduserrole',
      params: null
   },
   saveUserDetails: {
      url: 'saveuserdetails',
      params: null
   },
   getPortalUserDetails: {
      url: 'getuserattributes',
      params: null,
   },
   savePortalUserDetails: {
      url: 'saveuser',
      params: null
   },
   updatePortalUserDetails: {
      url: 'updateuser',
      params: null
   },
   deletePortalUserDetails: {
      url: 'disableuser',
      params: {
         userName: '', status: false
      }
   },
   getUserSettings: {
      url: 'getusersettings',
      params: null
   },
   saveUserSettings: {
      url: 'saveusersettings',
      params: null
   },
   masterSettings: {
      url: 'mastersettings',
      params: null
   },
   getMasterSetting: {
      url: 'getmastersettingbykey',
      params: null
   },
   saveNotificationTemplate: {
      url: 'savenotificationtemplate',
      params: null
   },
   updateNotificationTemplate: {
      url: 'updatenotificationtemplate',
      params: null
   },
   deleteNotificationTemplate: {
      url: 'deletenotificationtemplate',
      params: {
         id: 0, status: false
      }
   },
   getNotificationTemplateSearch: {
      url: 'getnotificationtemplatebysearchcriteria',
      params: null
   },
   getNotificationPlaceHolders: {
      url: 'getnotificationplaceholders',
      params: null
   },
   getSubscription: {
      url: 'getsubscriptionbysearchcriteria',
      params: null
   },
   saveSubscription: {
      url: 'savesubscription',
      params: null
   },
   updateSubscription: {
      url: 'updatesubscription',
      params: null
   },
   deleteSubscription: {
      url: 'deletesubscription',
      params: {
         id: 0, status: false
      }
   },
   savePromotion: {
      url: 'savepromotion',
      params: null
   },
   getPromotionSearch: {
      url: 'getpromotionbysearchcriteria',
      params: null
   },
   updatePromotion: {
      url: 'updatepromotion',
      params: null
   },
   deletePromotion: {
      url: 'deletepromotion',
      params: {
         id: '', status: false
      }
   },
   getPromotionDetails: {
      url: 'getpromotiondetails',
      params: { id: 0 }
   },
   getStoresSearch: {
      url: 'getstorebysearchcriteria',
      params: null
   },
   saveAnnouncement: {
      url: 'savenotificationjob',
      params: null
   },
   getAnnouncementSearch: {
      url: 'getnotificationjobbysearchcriteria',
      params: null
   },
   updateAnnouncement: {
      url: 'updatenotificationjob',
      params: null
   },
   getPromotionTypeSearch: {
      url: 'getpromotiontypes',
      params: null
   },
   getNotificationTypeSearch: {
      url: 'getnotificationtypes',
      params: null
   },
   getEventTypeSearch: {
      url: 'geteventtypes',
      params: null
   },
   getPriorityTypeSearch: {
      url: 'getprioritytypes',
      params: null
   },
   getUserSearch: {
      url: 'getuserdetails',
      params: null
   },
   saveOptinCart: {
      url: 'saveoptincart',
      parms: null
   },
   getOptinCartDetails: {
      url: 'getoptincartdetails',
      parms: null
   },
   getOptinCartSearch: {
      url: 'getoptincartdetails',
      parms: null
   },
   updateOptincart: {
      url: 'updateoptincart',
      parms: null
   },
   saveFaq: {
      url: 'savefaq',
      params: null
   },
   getFaqSearch: {
      url: 'getfaqbysearchcriteria',
      params: null
   },
   updateFaq: {
      url: 'updatefaq',
      params: null
   },
   deleteFaq: {
      url: 'deletefaq',
      params: {
         id: 0, status: false
      }
   },
   getCategoryTypeSearch: {
      url: 'getcategorytypes',
      params: null
   },
   getCartDetailsbyUserName: {
      url: 'getcartdetailsbyusername',
      params: null
   },
   saveCartItem: {
      url: 'saveoptincartitem',
      params: null
   },
   getCartItems: {
      url: 'getcartitemsbycartid',
      params: {
         cartId: '',
         cartItemStatus: 0
      }
   },
   deleteCartItem: {
      url: 'deletecartitems',
      params: {
         id: ''
      }
   },
   getCartItemFrequencyList: {
      url: 'getcartitemfrequencylist',
      params: null
   },
   getCartMembers: {
      url: 'getcartmembersbycartid',
      params: {
         id: ''
      }
   },
   updateCartName: {
      url: 'updatecartname',
      params: null
   },
   getMediaTypeSearch: {
      url: 'getmediatypes',
      params: null
   },
   getPromotionBanners: {
      url: 'getpromotionbanners',
      params: null
   },
   getNotificationSearch: {
      url: 'getnotificationbysearchcriteria',
      params: null
   },
   getUserFQAs: {
      url: 'getuserfaqs',
      params: null
   },
   getOptinCarts: {
      url: 'getoptincarts',
      params: null
   },
   saveUserGroup: {
      url: 'getoptincarts',
      params: null
   },
   deleteUserSession: {
      url: 'deletesession',
      params: null
   }
};
