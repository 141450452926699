import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { NbSidebarService, NbMenuService } from '@nebular/theme';
import { AuthService, LoaderService, RoutingService } from './providers';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  // need to remove before 1st build, it's using for reference purpose.
  subMenu = [
    {
      title: 'Dashboard',
      group: true,
    },
    {
      title: 'Buttons',
      icon: 'nb-home',
      link: '/pages/ui-features/buttons',
    },
    {
      title: 'Grid',
      icon: 'ion ion-ios-cloudy-night',
      link: '/pages/ui-features/grid',
    },
    {
      title: 'Users',
      icon: 'ion ion-ios-contact',
      link: '/pages/ui-features/icons',
    },
    {
      title: 'Modals',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/modals',
    },
    {
      title: 'Typography',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/typography',
    },
    {
      title: 'Animated Searches',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/search-fields',
    },
    {
      title: 'Tabs',
      icon: 'ion ion-android-radio-button-off',
      link: '/pages/ui-features/tabs',
    },
  ];
  constructor(
    private sidebarService: NbSidebarService,
    public authService: AuthService,
    public loaderService: LoaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private nbMenuService: NbMenuService,
    private routingService: RoutingService
  ) { }
  ngOnInit(): void {
    const user = {
      userName: 'suresh',
      password: 'qwer123$'
    };
    this.nbMenuService.onItemSelect().subscribe(menubag => {
      if (menubag && menubag.item && menubag.item.title === 'Logout') {
          this.routingService.logout();
      }
  });
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((event: NavigationStart) => {
      const url = this.routingService.navigateTo(event);
      if (event.url !== url) {
        this.router.navigate([url], { relativeTo: this.activatedRoute });
      }
    });
  }

  toggle() {
    this.sidebarService.toggle(true);
    return false;
  }
}
