import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { timer, Observable, Subject } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Injectable()
export class SharedService {
    dataBind: Function;
    private username: string;
    sessionData: { [key: string]: object };
    storage: Storage = sessionStorage;
    private resetSessionRefresh = new Subject<any>();
    previousInterruption: number;
    isApiRequestProcessing = false;
    get expiryKey() {
        return localStorage.getItem('ng2Idle.main.expiry');
    }
    set sessionVar(value: string) {
        this.storage.setItem('sessionVar', btoa(value));

    }
    get sessionVar() {
        const sessionVar = this.storage.getItem('sessionVar');
        try {
            return sessionVar ? atob(sessionVar) : '';
        } catch (error) {
            return '';
        }
    }
    get generalSettings() {
        const generalSettings = this.storage.getItem('generalSettings');
        try {
            return generalSettings ? JSON.parse(generalSettings) : {};
        } catch (error) {
            return { };
        }
    }
    set generalSettings (value: any) {
        this.storage.setItem( 'generalSettings', JSON.stringify(value));
    }
    get userName() {
        return this.username;
    }
    set userName(value: string) {
        this.username = value;
    }
    get permissionList() {
        const permissions = this.storage.getItem('permissionSet');
        try {
            return permissions ? JSON.parse(atob(permissions)) : [];
        } catch (error) {
            return [];
        }
    }
    set permissionList(value: Array<any>) {
        this.storage.setItem('permissionSet', btoa(JSON.stringify(value)));
    }

    constructor() { }
    clear() {
        this.storage.clear();
        localStorage.clear();
        this.username = '';
    }

    where(dataSource: Array<any>, key: string, value: any): Object {
        let result = null;
        for (let index = 0; index < dataSource.length; index++) {
            if (dataSource[index][key] === value) {
                result = dataSource[index];
                break;
            }
        }
        return result;
    }

    removeItem(dataSource: Array<any>, key: string, value: any): Array<any> {
        let pos = -1;
        for (let index = 0; index < dataSource.length; index++) {
            if (dataSource[index][key] === value) {
                pos = index;
                break;
            }
        }
        if (pos > -1) {
            dataSource.splice(pos, 1);
        }
        return dataSource;
    }

    menuIcon(value: string) {
        const permissions = this.permissionList;
        const menu = this.where(permissions, 'name', value) as any;
        return menu.icon;
    }

    getDisplayDate(date: Date): string {
        const months = ['January', 'February', 'March', 'April', 'May', 'June',
            'Jul', 'August', 'September', 'October', 'November', 'December'
        ];
        let result = '';
        if (date) {
            result = date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
        }
        return result;
    }

    generateId(dataSource: Array<any>, key: string): number {
        if (dataSource && dataSource.length > 0) {
           return dataSource[0][key] + 1;
        } else {
            return 1;
        }
    }

    sortData(dataSource: Array<any>, key: string): Array<any> {
        const temp = Array<any>().concat(dataSource);
        return temp.sort((a: any, b: any) => {
            if (a[key] > b[key]) {
                return 1;
            } else if (a[key] < b[key]) {
                return -1;
            } else { return 0; }
        });
    }

    deleteData(dataSource: Array<any>, key: string, value: any): void {
        if (dataSource && dataSource.length && key && value) {
            for (let index = 0; index < dataSource.length; index++) {
                if (dataSource[index][key] === value) {
                    dataSource.splice(index, 1);
                    break;
                }
            }
        }
    }

    markFormAsTouched(form: FormGroup): void {
        for (const key in (form.controls as any)) {
            if (form.controls.hasOwnProperty(key)) {
                form.controls[key].markAsTouched();
            }
        }
    }

    markFormAsUntouched(form: FormGroup): void {
        for (const key in (form.controls as any)) {
            if (form.controls.hasOwnProperty(key)) {
                form.controls[key].markAsPristine();
                form.controls[key].markAsUntouched();
            }
        }
    }

    onSessionTimeout(sessionTimeout: number): Observable<number> {
        const sessionTimer = this.resetSessionRefresh.pipe(startWith(0), switchMap(() => timer(1000, sessionTimeout * 60 * 1000)));
        return sessionTimer;
    }

    onSessionTimeoutReset() {
        this.resetSessionRefresh.next(void 0);
    }
}
