import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './core/landing-page/landing-page.component';
import { LoginComponent } from './core/login/login.component';
import { AuthGuard } from './providers';
import { UnauthorizedComponent } from './core/unauthorized/unauthorized';



const routes: Routes = [
    { path: '', redirectTo: 'landing-page', pathMatch: 'full' },
    { path: 'landing-page', component: LandingPageComponent },
    { path: 'login', component: LoginComponent },
    { path: 'user', canActivate: [AuthGuard], loadChildren: './admin/users/users.module#UsersModule' },
    { path: 'profile', canActivate: [AuthGuard], loadChildren: './modules/profile/profile.module#ProfileModule' },
    { path: 'role', canActivate: [AuthGuard], loadChildren: './admin/roles/roles.module#RolesModule'},
    { path: 'permission', canActivate: [AuthGuard], loadChildren: './admin/permissions/permissions.module#PermissionsModule'},
    { path: 'catalog', canActivate: [AuthGuard], loadChildren: './modules/catalog/catalog.module#CatalogModule' },
    { path: 'brand', canActivate: [AuthGuard], loadChildren: './modules/brand/brand.module#BrandModule' },
    { path: 'category', canActivate: [AuthGuard], loadChildren: './modules/category/category.module#CategoryModule' },
    { path: 'media', canActivate: [AuthGuard], loadChildren: './modules/media/media.module#MediaModule' },
    { path: 'productoption', canActivate: [AuthGuard], loadChildren: './modules/productoption/productoption.module#ProductOptionModule' },
    { path: 'product', canActivate: [AuthGuard], loadChildren: './modules/product/product.module#ProductModule' },
    { path: 'mastersetting', canActivate: [AuthGuard], loadChildren: './modules/mastersetting/mastersetting.module#MasterSettingModule' },
    { path: 'template', canActivate: [AuthGuard], loadChildren: './modules/template/template.module#TemplateModule' },
    { path: 'promotion', canActivate: [AuthGuard], loadChildren: './modules/promotion/promotion.module#PromotionModule' },
    { path: 'announcement', canActivate: [AuthGuard], loadChildren: './modules/announcement/announcement.module#AnnouncementModule' },
    { path: 'subscription', canActivate: [AuthGuard], loadChildren: './modules/subscription/subscription.module#SubscriptionModule' },
    { path: 'notification', canActivate: [AuthGuard], loadChildren: './modules/notification/notification.module#NotificationModule' },
   // { path: 'usercart', canActivate: [AuthGuard], loadChildren: './modules/usercart/usercart.module#UserCartModule' },
    { path: 'faqs', canActivate: [AuthGuard], loadChildren: './modules/faq/faq.module#FaqModule' },
    { path: 'logout', component: LandingPageComponent },
    { path: 'unauthorized', component: UnauthorizedComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
