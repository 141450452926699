import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiDictionary, Resources, ErrorConstants, SuccessConstants } from '../../constants';
import { Response, SaveCartItem } from '../../models';

@Injectable()
export class OptinCartItemService {
    constructor(private apiService: ApiService) { }

     saveCartItem(cartItem: SaveCartItem): Observable<any> {
        let apiUrl = ApiDictionary.saveCartItem.url;
        apiUrl = Resources.saveCartItem + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, cartItem).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
     }

     getCartItemsByCartId(id: any, cartItemStatus: number): Observable<any> {
         let apiUrl = ApiDictionary.getCartItems.url;
         apiUrl = Resources.getCartItems + apiUrl + '/' + id + '/' + cartItemStatus;
         const options = this.apiService.BindParamsToUrl(apiUrl, null);
         const observable = new Observable((observer) => {
             this.apiService.getData(options)
                .subscribe((result: any) => {
                    if (result instanceof HttpErrorResponse) {
                        const res: Response = {
                            failure: true, success: false, error: ErrorConstants.Api[result.status]
                        };
                        observer.next(res);
                    } else {
                        const res: Response = {
                            failure: false, success: true, error: null,
                            result: this.populateCartItems(result.OptinCartItems)
                        };
                        observer.next(res);
                    }
                }, (error) => {
                    const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                    observer.next(response);
            });
         });
         return observable;
     }

    private populateCartItems(optinCartItems: any): any {
        const cartItems = [];
        for (let index = 0; index < optinCartItems.length; index++) {
            const element = {
                productName: optinCartItems[index].ProductName,
                productId: optinCartItems[index].ProductId,
                brandName: optinCartItems[index].BrandName,
                skuId: optinCartItems[index].SkuId,
                price: optinCartItems[index].Price,
                qty: optinCartItems[index].Qty,
                weight: optinCartItems[index].Weight,
                itemCartId: optinCartItems[index].ItemCartId,
                cartItemStatus: optinCartItems[index].CartItemStatus,
                lastPurchasedOn: optinCartItems[index].LastPurchasedOn
                            ? new Date(optinCartItems[index].LastPurchasedOn.substr(0, optinCartItems[index].LastPurchasedOn.indexOf('T')))
                            : null,
                frequencyId: optinCartItems[index].FrequencyId,
                skuName: optinCartItems[index].SkuName,
                image: optinCartItems[index].Image ? Resources.getImage + optinCartItems[index].Image : '',
                thumbnail: optinCartItems[index].Thumbnail ? Resources.getThumbnail + optinCartItems[index].Thumbnail : '',
                preferredStoreId: optinCartItems[index].PreferredStoreId
            };
            cartItems.push(element);
        }
        return cartItems;
    }

    deleteCartItemById(id: any): Observable<any> {
        let apiUrl = ApiDictionary.deleteCartItem.url;
        apiUrl = Resources.deleteCartItem + apiUrl + '/' + id;
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: result.BooleanValue
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    getCartItemFrequencyList(): Observable<any> {
        let apiUrl = ApiDictionary.getCartItemFrequencyList.url;
         apiUrl = Resources.getCartItemFrequencyList + apiUrl;
         const options = this.apiService.BindParamsToUrl(apiUrl, null);
         const observable = new Observable((observer) => {
             this.apiService.getData(options)
                .subscribe((result: any) => {
                    if (result instanceof HttpErrorResponse) {
                        const res: Response = {
                            failure: true, success: false, error: ErrorConstants.Api[result.status]
                        };
                        observer.next(res);
                    } else {
                        const res: Response = {
                            failure: false, success: true, error: null,
                            result: this.populateCartFrequencyList(result.CartFrequencyList)
                        };
                        observer.next(res);
                    }
                }, (error: any) => {
                    const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                    observer.next(response);
                });
         });
         return observable;
    }

    private populateCartFrequencyList(frequencyList: Array<any>): Array<any> {
        const cartItemFrequencyList = [];
        for (let index = 0; index < frequencyList.length; index++) {
            const element = {
                name: frequencyList[index].Key,
                id: frequencyList[index].Value
            };
            cartItemFrequencyList.push(element);
        }
        return cartItemFrequencyList;
    }
}
