import { Injectable } from '@angular/core';
import { AppPermissiosSet, Resources } from '../../constants';

@Injectable()
export class AppPermissionService {

    private getRolePermissions(role: string) {
        let permissionSet = [];
        switch (role.toLowerCase()) {
            case 'admin':
            case 'operator': permissionSet = AppPermissiosSet.OperatorPermissionset;
                break;
            case 'user': permissionSet = AppPermissiosSet.UserPermissionset;
                break;
            default: break;
        }
        return permissionSet;
    }

    hasPermission(module: string, permission: string, permissionSet: any): boolean {
        let result = false;
        if (permissionSet.length > 0) {
            for (let index = 0; index < permissionSet.length; index++) {
                const permissionItem = permissionSet[index];
                if (permissionItem.name.toLowerCase() === module) {
                    result = permissionItem[permission];
                    break;
                }
            }
        }
        return result;
    }

    getNavigationMenus(permissionSet: any) {
        if (permissionSet.length > 0) {
            permissionSet = permissionSet.filter((element: any) => {
                element.name = element.name.indexOf('_') > -1 ? element.name.replace('_', ' ') : element.name;
                return element.canMenuItem;
            });
        }
        permissionSet.push({
            name: 'Logout', sortBy: 9, canCreate: false, canEdit: false, canDelete: false,
            canView: true, canMenuItem: true, src: 'log-out', navigatePage: 'LandingPage'
        });
        return permissionSet;
    }

    getDefaultHomePage(permissionSet: any) {
        let result = '';
        for (let index = 0; index < permissionSet.length; index++) {
            const element = permissionSet[index];
            if (element.sortBy === 1) {
                result = element.navigatePage;
                break;
            }
        }
        return result;
    }
}


