import { Injectable } from '@angular/core';
import { ApiDictionary, Resources, ErrorConstants, SuccessConstants } from '../../constants';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Response, Category } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class CategoryService {
    constructor(private apiService: ApiService) { }

    saveCategory(category: Category): Observable<any> {
        let apiUrl = ApiDictionary.saveCategory.url;
        apiUrl = Resources.saveCategory + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, category).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    searchCategory(searchObj: any): Observable<any> {
        let categoryUrl = ApiDictionary.getCategorySearch.url;
        categoryUrl = Resources.getCategorySearch + categoryUrl;
        const observable = new Observable((observer) => {
            const request = {
                Name: searchObj.name, IsActive: searchObj.status, ExpiryDate: searchObj.expiryDate.toDateString(),
                CatalogId: searchObj.catalogId
            };
            this.apiService.postData(categoryUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateCategoryList(result.Catagories), error: null 
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateCategoryList(catagories: any): any {
        const catalogList = [];
        for (let index = 0; index < catagories.length; index++) {
            const catalog = {
                id: catagories[index].Id,
                name: catagories[index].Name,
                startDate: catagories[index].StartDate ?
                    (catagories[index].StartDate.indexOf('T') > 0
                        ? new Date(catagories[index].StartDate.substring(0, catagories[index].StartDate.indexOf('T')))
                        : new Date(catagories[index].StartDate))
                    : null,
                endDate: catagories[index].EndDate ?
                    (catagories[index].EndDate.indexOf('T') > 0
                        ? new Date(catagories[index].EndDate.substring(0, catagories[index].EndDate.indexOf('T')))
                        : new Date(catagories[index].EndDate)) : null,
                isActive: catagories[index].IsActive,
                logo: catagories[index].Image ? catagories[index].Image : '',
                thumbnail: catagories[index].Thumbnail ? catagories[index].Thumbnail : '',
                rootCategory: catagories[index].RootCategory,
                parentCategoryId: catagories[index].ParentCategoryId,
                description: catagories[index].Description,
                longDescription: catagories[index].LongDescription
            };
            catalogList.push(catalog);
        }
        return catalogList;
    }

    updateCategory(category: Category): Observable<any> {
        let apiUrl = ApiDictionary.updateCategory.url;
        apiUrl = Resources.updateCategory + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.updateData(apiUrl, category).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: category.Name + SuccessConstants.Messages.Save_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    deleteCategory(id: number, status: boolean, name: string): Observable<any> {
        let apiUrl = ApiDictionary.deleteCategory.url;
        apiUrl = Resources.deleteCategory + apiUrl + '/' + id + '/' + (!status);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: name + (!status ? SuccessConstants.Messages.Activate_Success : SuccessConstants.Messages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
}
