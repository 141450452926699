import { Injectable } from '@angular/core';
import { ApiDictionary, Resources, ErrorConstants, SuccessConstants } from '../../constants';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Response, Brand } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class BrandService {
    constructor(private apiService: ApiService) { }

    saveBrand(brand: Brand): Observable<any> {
        let apiUrl = ApiDictionary.saveBrand.url;
        apiUrl = Resources.saveBrand + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, brand).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    searchBrand(searchObj: any): Observable<any> {
        let brandUrl = ApiDictionary.getBrandSearch.url;
        brandUrl = Resources.getCatalogSearch + brandUrl;
        const observable = new Observable((observer) => {
            const request = { Name: searchObj.name, IsActive: searchObj.status };
            this.apiService.postData(brandUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateBrandList(result.Brands), error: null 
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateBrandList(brands: any): any {
        const brandList = [];
        for (let index = 0; index < brands.length; index++) {
            const catalog = {
                id: brands[index].Id,
                name: brands[index].Name,
                isActive: brands[index].IsActive
            };
            brandList.push(catalog);
        }
        return brandList;
    }

    updateBrand(brand: Brand): Observable<any> {
        let apiUrl = ApiDictionary.updateBrand.url;
        apiUrl = Resources.updateBrand + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.updateData(apiUrl, brand).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: brand.Name + SuccessConstants.Messages.Save_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    deleteBrand(id: number, status: boolean, name: string): Observable<any> {
        let apiUrl = ApiDictionary.deleteBrand.url;
        apiUrl = Resources.deleteBrand + apiUrl + '/' + id + '/' + (!status);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: name + (!status ? SuccessConstants.Messages.Activate_Success : SuccessConstants.Messages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
}
