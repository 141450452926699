import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { SharedService, ModalPopupService } from '../../../providers';
import { AlertComponent } from '../alert/alert.component';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-file-upload',
    templateUrl: 'fileUpload.component.html',
    styleUrls: ['./fileUpload.component.css']
})
export class FileUploadComponent implements OnInit {
    @ViewChild('fileUpload') fileUpload;
    // tslint:disable-next-line:no-input-rename
    @Input('file-display-label') fileLabel: string;
    @Input('accept') accept: string;
    // tslint:disable-next-line:no-input-rename
    @Input('invalid-message') invalidFileTypeMessage: string;
    // value of size limit is in Kb
    // tslint:disable-next-line:no-input-rename
    @Input('size') sizeLimit: string;
    // tslint:disable-next-line:no-input-rename
    @Input('filepath') fileLink: string;
    @Output('upload') upload = new EventEmitter<string>();
    filePath: '';

    constructor(private sharedService: SharedService, private modalPopupService: ModalPopupService) { }
    ngOnInit(): void { }

    /// Using this method only in any edit component logic to send filePath after getting data from api
    fileUploadCBRef(fileUploadProps: any) {
        this.filePath = fileUploadProps.filePath;
    }

    addFiles(): void {
        this.fileUpload.nativeElement.click();
    }

    onChange(event: any): void {
        const file: File = event.srcElement.files[0];
        if (this.verifyFileType(file.type) && this.verifyFileSize(file)) {
            this.getBase64String(file).subscribe((response: any) => {
                if (response instanceof Error) {
                    this.filePath = '';
                } else {
                    this.filePath = response;
                }
                event.target.value = '';
                this.upload.emit(this.filePath.replace(/^data:(.*;base64,)?/, '') + ',' + file.type.substr(file.type.indexOf('/') + 1));
            });
        } else if (!this.verifyFileSize(file)) {
            event.target.value = '';
            this.filePath = '';
            this.upload.emit(this.filePath);
            this.modalPopupService.openPopup(AlertComponent, { message: 'File size should be less than ${this.sizeLimit}' });
        } else {
            event.target.value = '';
            this.filePath = '';
            this.upload.emit(this.filePath);
            this.modalPopupService.openPopup(AlertComponent, { message: this.invalidFileTypeMessage });
        }
    }

    getBase64String(file: File) {
        const observable = new Observable((observer) => {
            const reader = new FileReader();
            reader.onload = () => {
                observer.next(reader.result.toString());
            };
            reader.onerror = error => {
                observer.error(error);
            };
            reader.readAsDataURL(file);
        });
        return observable;
    }

    verifyFileType(fileType: string): boolean {
        const acceptingFileTypes = this.accept.split(',');
        let result = false;
        for (let index = 0; index < acceptingFileTypes.length; index++) {
            const element = acceptingFileTypes[index].substr(1);
            if (fileType.indexOf(element)) {
                result = true;
                break;
            }
        }
        return result;
    }

    verifyFileSize(file: File): boolean {
        let result = false;
        const fileSize = this.sizeLimit ? parseInt(this.sizeLimit, 0) : 512;
        if ((file.size / 1024) > 1 && (file.size / 1024) < fileSize) {
           result = true;
        }
        return result;
    }

    removeImage() {
        this.filePath = '';
        this.upload.emit(this.filePath);
    }
}
