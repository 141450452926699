import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiDictionary, Resources, ErrorConstants, SuccessConstants } from '../../constants';
import { Response, OptinCart, UpdateCartName } from '../../models';

@Injectable()
export class OptinCartService {
    constructor(private apiService: ApiService) { }

    saveOptinCart(optinCart: OptinCart): Observable<any> {
        let apiUrl = ApiDictionary.saveOptinCart.url;
        apiUrl = Resources.saveOptinCart + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, optinCart).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    getOptinCartByUserName(name: string): Observable<any> {
        let apiUrl = ApiDictionary.getOptinCartDetails.url;
        apiUrl = Resources.getOptinCartDetails + apiUrl + '/' + name;
        const options = this.apiService.BindParamsToUrl(apiUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(options).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateOptinCartDetails(result), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    private populateOptinCartDetails(optinCart: any) {
        const items: any = {};
        for (let index = 0; index < optinCart.optinCarts.length; index++) {
            const element = optinCart.optinCarts[index];
            const userDetails = {
                userName: element.UserName,
                subscriptionId: element.SubscriptionId,
                isCartAdmin: element.IsCartAdmin,
                cartName: element.CartName,
                createdBy: element.CreatedBy,
                storeId: element.StoreId,
            };
            items.push(userDetails);
        }
        return items;
    }

    searchOptinCart(searchObj: any): Observable<any> {
        let apiUrl = ApiDictionary.getOptinCartSearch.url;
        apiUrl = Resources.getOptinCartSearch + apiUrl + '/' + searchObj;
        const options = this.apiService.BindParamsToUrl(apiUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(options).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateOptinCartList(result.OptinCarts), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    getOptinCarts(): Observable<any> {
        let typeUrl = ApiDictionary.getOptinCarts.url;
        typeUrl = Resources.getOptinCartList + typeUrl;
        const options = this.apiService.BindParamsToUrl(typeUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(options).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateCartList(result.OptinCart), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    private populateCartList(carts: any): any {
        const cartList = [];
        for (let index = 0; index < carts.length; index++) {
            const cart = {
                id : carts[index].CartId,
                name : carts[index].CartName
            };
            cartList.push(cart);
        }
        return cartList;
    }
    private populateOptinCartList(optinCart: any): any {
        const optinCartList = [];
        for (let index = 0; index < optinCart.length; index++) {
            const user = {
                cartId: optinCart[index].CartId,
                cartName: optinCart[index].CartName,
                cartTotalMRP: optinCart[index].CartTotalMRP,
                storeId: optinCart[index].StoreId,
                subscriptionName: optinCart[index].SubscriptionName,
                isActive: optinCart[index].IsActive,
                storeName: optinCart[index].StoreName,
                createdOn: optinCart[index].CreatedOn ?
                (optinCart[index].CreatedOn.indexOf('T') > 0
                    ? new Date(optinCart[index].CreatedOn.substring(0, optinCart[index].CreatedOn.indexOf('T')))
                    : new Date(optinCart[index].CreatedOn))
                : null,
                cartMembers: optinCart[index].CartMembers
            };
            optinCartList.push(user);
        }
        return optinCartList;
    }


    updateOptinCart(optinCart: OptinCart): Observable<any> {
        let apiUrl = ApiDictionary.updateOptincart.url;
        apiUrl = Resources.updateOptinCart + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.updateData(apiUrl, optinCart).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: optinCart.CartName + SuccessConstants.Messages.Save_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    getCartDetailsByUsernameForApp(userName: string): Observable<any> {
        let apiUrl = ApiDictionary.getCartDetailsbyUserName.url;
        apiUrl = Resources.getCartDetailsbyUserName + apiUrl + '/' + userName;
        const params = this.apiService.BindParamsToUrl(apiUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(params).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateCartDetailsByUserName(result), error: null
                    };
                    observer.next(res);
                }
            }, error => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateCartDetailsByUserName(result: any): any {
        const response: any = {};
        if (result) {
            response.cartName = result.CartName;
            response.subscriptionName = result.SubscriptionName;
            response.maxMembers = result.MaxMembers;
            response.cartId = result.CartId;
            response.cartMembers = result.CartMembers;
        }
        return response;
    }

    updateCartName(updateCartName: UpdateCartName): Observable<any> {
        let apiUrl = ApiDictionary.updateCartName.url;
        apiUrl = Resources.updateCartName + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.updateData(apiUrl, updateCartName).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.BooleanValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
}
