import { Component, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { filter, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, MasterSettingService, ModalPopupService, ProfileService } from '../../providers';
import { SetPasswordComponent } from '../set-password/set-password.component';
import { RoutingService, SharedService } from '../../providers';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userMenu = [{ title: 'Profile' }, { title: 'Change Password' }, { title: 'Log out' }];
  position = 'normal';
  constructor(
    public authService: AuthService, private sidebarService: NbSidebarService,
    private nbMenuService: NbMenuService, private router: Router,
    private activateRoute: ActivatedRoute, public sharedService: SharedService,
    private profileService: ProfileService, public routingService: RoutingService,
    private modelPopup: ModalPopupService , private masterSettingService: MasterSettingService
  ) { }
  ngOnInit(): void {
    this.nbMenuService.onItemClick().pipe(filter(({ tag }) => tag === 'header-menu'), map(({ item: { title } }) => title))
      .subscribe(title => {
        if (title === 'Log out') {
          this.routingService.logout();
          this.router.navigate(['./landing-page'], { relativeTo: this.activateRoute });
        } else if (title === 'Profile') {
          this.router.navigate(['./profile']);
             } else if (title === 'Change Password') {
          this.navigateChangePassword();
        }
      });
      this.profileService.getProfileUser().subscribe((response) => {
        if (response.success) {
          this.sharedService.userName = response.result.preferred_username;
        }
      });
  }

  navigateChangePassword(): void {
        const data = {
          userName: this.sharedService.userName,
          message: '',
          formType: 'ChangePassword'
        };
        this.modelPopup.openPopup<SetPasswordComponent>(SetPasswordComponent, data);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  navigateToLogin(): void {
    this.router.navigate(['login']);
  }

  goToHome(): void {
    this.router.navigate(['/']);
  }
}
