import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { LoaderService } from './loader.service';
import { AuthService } from './user/auth.service';
import { SharedService  } from './shared.Service';
import { Observable, throwError } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators';

@Injectable()
export class ProgressInfoService implements HttpInterceptor {
    constructor(private loadingService: LoaderService, private authService: AuthService, private sharedService: SharedService) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // tslint:disable-next-line:prefer-const
        let requestProgress = this.loadingService.start();
        const authReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${this.authService.token}`)
        });
        const currDate = new Date();
        this.sharedService.previousInterruption = +(currDate.getHours() + '' + currDate.getMinutes());
        this.sharedService.isApiRequestProcessing = true;
        if (!window.navigator.onLine) {
            this.loadingService.close(requestProgress);
            return throwError(new HttpErrorResponse({ error: 'Not connected to internet', statusText: 'InternetNotConnected', status: 0 }));
        }
        return next.handle(authReq).pipe(map(event => {
            if (event instanceof HttpResponse || event instanceof HttpErrorResponse) {
                this.loadingService.close(requestProgress);
                this.sharedService.isApiRequestProcessing = false;
            }
            return event;
        }), timeout(60000), catchError((error: HttpErrorResponse, caught) => {
            this.sharedService.isApiRequestProcessing = false;
            this.loadingService.close(requestProgress);
            return throwError(new HttpErrorResponse(
                {
                    error: error.error.Code ? error.error.Message : 'Request timeout', status: error.status || 408,
                    statusText: error.statusText || 'RequestTimeout'
                }));
        }));
    }
}
