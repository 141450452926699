import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { GridOptions, DisplayColumns, OMDatasource, EventTypes } from './om-grid.model';
import { SharedService } from '../../../providers';

@Component({
    selector: 'app-om-grid',
    templateUrl: 'om-grid.component.html',
    styleUrls: ['./om-grid.component.css']
})
export class OMGridComponent implements OnInit {
    // tslint:disable-next-line:no-input-rename
    @Input('table-name') tablename: string;
    @Output() callBack = new EventEmitter<any>();
    itemsCount = 5;
    icon = 'fa fa-user';
    totalItemsCount = 0; // For server side pagination total items count
    currPage = 1;
    itemsPerPage: Array<any> = [5, 10, 15, 20, 25, 30];
    source: Array<any>;
    pages: Array<number> = [];
    headerNames: Array<string>;
    searchTerm = '';
    tempSource: Array<any> = [];
    displayColumns: DisplayColumns[];
    options: GridOptions;
    sort: string;
    sortingColumn: string;
    constructor(private sharedService: SharedService) {
        this.sharedService.dataBind = (source) => { this.bind(source); };
        this.source = [];
    }

    ngOnInit(): void {
        if (this.callBack) {
            this.callBack.emit((source) => { this.bind(source); });
        }
    }

    bind(param: OMDatasource): void {
        this.options = param.options;
        this.displayColumns = param.displayColumns;
        this.icon = param.icon;
        this.options.deleteCallback = this.options.deleteCallback || ((data) => false);
        this.options.viewCallback = this.options.viewCallback || ((data) => false);
        this.options.editCallback = this.options.editCallback || ((data) => false);
        this.options.masterCartCallback = this.options.masterCartCallback || ((data) => false);
        if (param.source) { // if source length > 0 then comes here
            if (this.displayColumns && this.displayColumns.length > 0) { // if display columns length > 0 other wise goes to else block
                this.headerNames = this.displayColumns.map((column) => column.headerName);
                if (this.options && (this.options.showEdit || this.options.showDelete ||
                    this.options.showView || this.options.showMasterCart)) {
                    this.headerNames.push('Actions');
                }
            } else {
                this.headerNames = Object.keys(param.source[0]);
            }
            this.source = param.source;
            if (this.options && !this.options.serverSidePagination) {
                const pageCount = param.source.length % this.itemsCount > 0 ?
                    Math.floor(param.source.length / this.itemsCount) + 1 :
                    Math.floor(param.source.length / this.itemsCount);
                this.pages = Array(pageCount).fill(1).map((x, index) => index + 1);
                this.totalItemsCount = 0;
            } else {
                this.totalItemsCount = param.count || 0;
                const pageCount = this.totalItemsCount % this.itemsCount > 0 ?
                    Math.floor(this.totalItemsCount / this.itemsCount) + 1 :
                    Math.floor(this.totalItemsCount / this.itemsCount);
                this.pages = param.count > 0 ? Array(pageCount).fill(1).map((x, index) => index + 1) :
                    Array(1).fill(0);
            }
            this.currPage = param.eventType !== EventTypes.Pagination.toString() ? this.pages[0] : this.currPage;
            this.updateDefaultSortingDetails(param.eventType !== EventTypes.Sorting.toString() ? 'asc' : '', param.eventType);
            this.tempSource = [];
        } else {
            throw new Error('Data source should not be null or undefined');
        }
    }

    itemChangeEvent() {
        if (this.options && !this.options.serverSidePagination) {
            const pageCount = this.source.length % this.itemsCount > 0 ?
                Math.floor(this.source.length / this.itemsCount) + 1 :
                Math.floor(this.source.length / this.itemsCount);
            this.pages = Array(pageCount).fill(1).map((x, index) => index + 1);
            this.currPage = this.pages.length > 0 ? this.pages[0] : 1;
        } else if (this.options && this.options.serverSidePagination) {
            const searchObj = {
                key: '', value: '', limitTo: this.itemsCount,
                pageIndex: this.currPage, eventType: EventTypes.ItemChange
            };
            this.options.serverSidePaginationCallback(searchObj);
        }
    }

    pageChangeEvent() {
        if (this.options && this.options.serverSidePagination) {
            const searchObj = {
                key: '', value: '', limitTo: this.itemsCount,
                pageIndex: this.currPage, eventType: EventTypes.Pagination
            };
            this.options.serverSidePaginationCallback(searchObj);
        }
    }

    filterItems() {
        if (this.searchTerm && this.tempSource.length === 0) {
            this.tempSource = this.source;
        }
        if (!this.searchTerm) {
            this.source = this.tempSource;
        } else {
            this.source = this.tempSource.filter((item) => {
                return this.displayColumns.map((x) => x.columnName).some(key => item.hasOwnProperty(key) &&
                    item[key].toString().indexOf(this.searchTerm) > -1);
            });
        }
        if (this.options && !this.options.serverSidePagination) {
            this.itemChangeEvent();
        }
    }

    updateDefaultSortingDetails(sorting: string, eventType: string) {
        this.sort = sorting;
        if (eventType !== EventTypes.Sorting) {
            this.sortingColumn = this.options && (this.options.serverSideSorting || this.displayColumns.length < 0) ? '' :
                (this.displayColumns.length > 0 && this.displayColumns[0].columnName) ?
                    this.displayColumns[0].columnName :
                    (this.displayColumns.length > 1 && this.displayColumns[1].columnName) ?
                        this.displayColumns[1].columnName : '';
        }
    }

    updateSortingDetails(headerName: string) {
        if (headerName) {
            this.displayColumns.forEach(element => {
                if (headerName === element.headerName && headerName !== 'Actions') {
                    this.sort = this.sortingColumn === element.columnName ? this.sort === 'desc' ? 'asc' : 'desc' : 'asc';
                    this.sortingColumn = element.columnName;
                    return;
                }
            });
        } else if (this.displayColumns.length > 0 && this.displayColumns[0].columnName) {
            this.sortingColumn = this.displayColumns[0].columnName;
            this.sort = 'asc';
        } else if (this.displayColumns.length > 1 && this.displayColumns[1].columnName) {
            this.sortingColumn = this.displayColumns[0].columnName;
            this.sort = 'asc';
        }
        if (this.options && this.options.serverSortingCallback) {
            this.options.serverSortingCallback({ eventType: EventTypes.Sorting, sortingColumn: this.sortingColumn, sorting: this.sort });
        }
    }

    displaySortingDirection(headerName: string): boolean {
        let result = false;
        for (let index = 0; index < this.displayColumns.length; index++) {
            if (headerName && this.displayColumns[index].headerName === headerName) {
                result = this.sortingColumn === this.displayColumns[index].columnName;
                break;
            }
        }
        return result;
    }

    displayDate(date: Date): string {
        return this.sharedService.getDisplayDate(date);
    }
}
