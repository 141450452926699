import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Response } from '../../models';
import { ApiDictionary, ErrorConstants, Resources } from '../../constants';

@Injectable()
export class OptinCartMemberService {
    constructor(private apiService: ApiService) { }

    getCartMembersByCartId(id: any): Observable<any> {
        let apiUrl = ApiDictionary.getCartMembers.url;
        apiUrl = Resources.getCartMembers + apiUrl + '/' + id;
        const options = this.apiService.BindParamsToUrl(apiUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(options)
               .subscribe((result: any) => {
                   if (result instanceof HttpErrorResponse) {
                       const res: Response = {
                           failure: true, success: false, error: ErrorConstants.Api[result.status]
                       };
                       observer.next(res);
                   } else {
                       const res: Response = {
                           failure: false, success: true, error: null,
                           result: {
                              contactEmail: result.ContactEmail,
                              contactNumber: result.ContactNumber,
                              members: this.populateCartMembers(result.CartMembers)
                            }
                       };
                       observer.next(res);
                   }
               }, (error) => {
                   const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                   observer.next(response);
           });
        });
        return observable;
    }

   private populateCartMembers(cartMembers: any): any {
       const cartMemberList = [];
       for (let index = 0; index < cartMembers.length; index++) {
           const element = {
               firstName: cartMembers[index].FirstName,
               lastName: cartMembers[index].LastName,
               userName: cartMembers[index].UserName,
               createdDate: cartMembers[index].CreatedDate,
               isCartAdmin: cartMembers[index].IsCartAdmin,
               isActive: cartMembers[index].IsActive
           };
           cartMemberList.push(element);
       }
       return cartMemberList;
   }
}
