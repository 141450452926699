import { Attribute, forwardRef, Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[dateComparison][formControlName]',
    providers: [{ provide: NG_VALIDATORS, useExisting:  DateComparisonValidator, multi: true }]
})
export class DateComparisonValidator implements Validator {
    constructor(@Attribute('dateComparison') public dateComparison: string,
                @Attribute('dateToCompare') public dateToCompare: string) { }
    validate(c: AbstractControl): { [key: string]: any } {
        const dateComparisonRef = c.parent.get(this.dateComparison);
        const dateToCompareRef = c.parent.get(this.dateToCompare);
        if (dateToCompareRef && dateComparisonRef) {
            const dateToCompare = dateToCompareRef.value as Date;
            const dateComparisonVal = dateComparisonRef.value as Date;
            if (dateToCompare >= dateComparisonVal) {
                // dateToCompareRef.setErrors({ dateComparison: true });
                return { dateComparison: true };
            } else {
                 dateToCompareRef.setErrors(null);
                // return { dateComparison: false };
            }
        }
        return null;
    }

}
