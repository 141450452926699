import { Injectable, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { Response, Profile } from '../../models';
import { UserData, ICognitoUserAttributeData } from 'amazon-cognito-identity-js';
import { SuccessConstants, ErrorConstants } from 'src/app/constants';

@Injectable()
export class ProfileService implements OnInit {
    private profileUser: any = null;
    constructor(private auth: AuthService) { }
    ngOnInit() {

    }

    getProfileUser(): Observable<any> {
        const observable = new Observable((observer) => {
            this.auth.isAuthenticated().subscribe((success) => {
                if (!success) {
                    const response: Response = {
                        error: ErrorConstants.Cognito['Invalid_Session'],
                        failure: true, success: false, result: null
                    };
                    observer.next(response);
                } else if (success) {
                    !this.profileUser ? this.auth.getAuthenticatedUser().getUserData((err, result) => {
                        if (err) {
                            this.profileUser = null;
                            const response: Response = { error: err, failure: true, success: false, result: null };
                            observer.next(response);
                        } else {
                            const response: Response = { error: null, failure: false, success: true, result: this.buildGetProfile(result) };
                            this.profileUser = response.result;
                            observer.next(response);
                        }
                    }) : observer.next({ error: null, failure: false, success: true, result: this.profileUser });
                }
            });
        });
        return observable;
    }

    buildGetProfile(result: UserData): any {
        if (result) {
            const response: Profile = {
                Username: result.Username,
                email: ''
            };
            result.UserAttributes.forEach(element => {
                switch (element.Name) {
                    case 'custom:Role':
                        response.role = element.Value;
                        break;
                    case 'email':
                        response.email = element.Value;
                        break;
                    case 'email_verified':
                        response.email_verified = (element.Value.toLowerCase() === 'true') ? true : false;
                        break;
                    case 'phone_number':
                        response.phone_number = element.Value.indexOf('+91') > -1 ? element.Value.substring(3) : '';
                        break;
                    case 'preferred_username':
                        response.preferred_username = element.Value;
                        break;
                    case 'given_name':
                        response.given_name = element.Value;
                        break;
                    case 'family_name':
                        response.family_name = element.Value;
                        break;
                    case 'sub':
                        response.sub = element.Value;
                        break;
                    case 'phone_number_verified':
                        response.phone_number_verified = (element.Value.toLowerCase() === 'true') ? true : false;
                        break;
                    case 'custom:Tier':
                        response.tier = element.Value;
                        break;
                    default:
                        break;
                }
            });
            return response;
        }
        return {};
    }

    updateProfile(profileInfo: Profile): Observable<any> {
        const cognitoAttributes: ICognitoUserAttributeData[] = [];
        const observable = new Observable<any>((observer) => {
            this.auth.isAuthenticated().subscribe((success) => {
                if (!success) {
                    const response: Response = {
                        error: ErrorConstants.Cognito['Invalid_Session'],
                        failure: true, success: false, result: null
                    };
                    observer.next(response);
                } else if (success) {
                    Object.keys(profileInfo).forEach(key => {
                        if (key !== 'Username' && key !== 'phone_number_verified' && profileInfo[key]) {
                            if (key === 'phone_number') {
                                cognitoAttributes.push({ Name: key, Value: '+91' + profileInfo[key] });
                            } else {
                                cognitoAttributes.push({ Name: key, Value: profileInfo[key].toString().trim() });
                            }
                        }
                    });
                }
                if (cognitoAttributes.length > 0) {
                    this.auth.cognitoUser.updateAttributes(cognitoAttributes, (err: any, result) => {
                        if (err) {
                            const response: Response = { error: err, failure: true, success: false, result: null };
                            response.error.message = (err.code === 'AliasExistsException')
                                ? `Provided username  ${profileInfo.preferred_username} already exists. Please try other user name`
                                : response.error.message;
                            observer.next(response);
                        } else if (success) {
                            this.profileUser = null;
                            this.getProfileUser();
                            const response: Response = {
                                error: null, failure: false, success: true,
                                result: SuccessConstants.Messages.Profile_Update
                            };
                            observer.next(response);
                        }
                        observer.complete();
                    });
                } else {
                    const response: Response = { error: 'Update atleast required fields', failure: true, success: false, result: null };
                    observer.next(response);
                }
            });
        });
        return observable;
    }

    clearProfile(): void {
        this.profileUser = null;
    }
}
