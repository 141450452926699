import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
@Component({
    selector: 'app-progressbar',
    templateUrl: 'progressbar.component.html',
    styleUrls: ['progressbar.component.css']
})
export class ProgressBarComponent {
   mode = 'indeterminate';
   color = 'accent';
   constructor(private dialogRef: MatDialogRef<ProgressBarComponent>) { }
}
