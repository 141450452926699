import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-users',
    templateUrl: 'users.component.html'
})
export class UsersComponent implements OnInit {
    users: Array<any> = [];
    constructor() { }
    ngOnInit(): void {

    }
}

