import { Injectable } from '@angular/core';
import { ApiDictionary, Resources, ErrorConstants, SuccessConstants } from '../../constants';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Response, Faq } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class FaqService {
    constructor(private apiService: ApiService) { }

    saveFaq(faq: Faq): Observable<any> {
        let apiUrl = ApiDictionary.saveFaq.url;
        apiUrl = Resources.saveFaq + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, faq).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    searchFaq(searchObj: any): Observable<any> {
        let faqUrl = ApiDictionary.getFaqSearch.url;
        faqUrl = Resources.getFaqSearch + faqUrl;
        const observable = new Observable((observer) => {
            const request = { CategoryType: searchObj.categoryId, IsActive: searchObj.status };
            this.apiService.postData(faqUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateFaqList(result.FAQS), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateFaqList(faqs: any): any {
        const faqList = [];
        for (let index = 0; index < faqs.length; index++) {
            const catalog = {
                id: faqs[index].Id,
                categoryType: faqs[index].CategoryType,
                categoryId: faqs[index].CategoryId,
                question: faqs[index].FAQTopic,
                answer: faqs[index].FAQBody,
                isActive: faqs[index].IsActive
            };
            faqList.push(catalog);
        }
        return faqList;
    }
    searchCategoryTypes(): Observable<any> {
        let typeUrl = ApiDictionary.getCategoryTypeSearch.url;
        typeUrl = Resources.getCategoryTypeSearch + typeUrl;
        const options = this.apiService.BindParamsToUrl(typeUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(options).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateCategoryTypes(result.Types), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateCategoryTypes(types: any): any {
        const typeList = [];
        for (let index = 0; index < types.length; index++) {
            const store = {
                name : types[index].Key,
                id : types[index].Value,
            };
            typeList.push(store);
        }
        return typeList;
    }
    updateFaq(faq: Faq): Observable<any> {
        let apiUrl = ApiDictionary.updateFaq.url;
        apiUrl = Resources.updateFaq + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.updateData(apiUrl, faq).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: 'Faq' + SuccessConstants.Messages.Update_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    deleteFaq(id: number, status: boolean): Observable<any> {
        let apiUrl = ApiDictionary.deleteFaq.url;
        apiUrl = Resources.deleteFaq + apiUrl + '/' + id + '/' + (!status);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result:   'Faq' + (!status ? SuccessConstants.Messages.Activate_Success :
                            SuccessConstants.Messages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    getUserFAQs(): Observable<any> {
        const apiUrl = Resources.getUserFAQs + ApiDictionary.getUserFQAs.url;
        const options = this.apiService.BindParamsToUrl(apiUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(options).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateUserFAQs(result.UserFAQs), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateUserFAQs(userFAQs: Array<any>): Array<any> {
        const response = [];
        if (userFAQs && userFAQs.length > 0) {
            for (let index = 0; index < userFAQs.length; index++) {
                const element = userFAQs[index];
                const faqs = [];
                for (const key in element.FAQ) {
                    if (element.FAQ.hasOwnProperty(key)) {
                        faqs.push({'question': key, 'answer': element.FAQ[key]});
                    }
                }
                response.push({'categoryName': element.CategoryType, 'faqs': faqs});
            }
        }
        return response;
    }
}
