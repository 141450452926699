import { Injectable } from '@angular/core';
import { ProgressBarComponent } from '../utils';
import { MatDialog, MatDialogRef } from '@angular/material';

@Injectable()
export class LoaderService {
    loading = false;
    constructor(private dialog: MatDialog) { }
    start(): any {
        // const dialogRef = this.dialog.open(ProgressBarComponent, {
        //     width: '400px',
        //     disableClose: true
        // });
        this.loading = true;
        return ''; // dialogRef;
    }

    close(dialogRef: MatDialogRef<ProgressBarComponent, any>): void {
        this.loading = false;
        // dialogRef.close();
    }
}
