import { Injectable } from '@angular/core';
import { ApiDictionary, Resources, ErrorConstants } from '../../constants';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Response, MasterSettingRequest } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class MasterSettingService {
    constructor(private apiService: ApiService) { }

    saveMasterSettings(request: MasterSettingRequest): Observable<any> {
        let apiUrl = ApiDictionary.masterSettings.url;
        apiUrl = Resources.masterSettings + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
    getMasterSetting(key: string): Observable<any> {
        let apiUrl = ApiDictionary.getMasterSetting.url;
        apiUrl = Resources.getMasterSetting + apiUrl + '/' + key;
        const options = this.apiService.BindParamsToUrl(apiUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(options).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateMasterSettings(result), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    private populateMasterSettings(setting: any): any {
        const masterSettingResponse = {
            masterSMS: {
                allowOverRide: (setting.MasterSMS ? setting.MasterSMS.AllowUserOverride : true),
                smsEnabled: (setting.MasterSMS ? setting.MasterSMS.SMSEnabled : true),
                smsUrl: (setting.MasterSMS ? setting.MasterSMS.SMSURL : null),
                accessToken: (setting.MasterSMS ? setting.MasterSMS.AccesToken : null),
                smsLogUrl: (setting.MasterSMS ? setting.MasterSMS.SMSLogUrl : null),
                clientId: (setting.MasterSMS ? setting.MasterSMS.ClientId : null),
            },
            masterEmail: {
                allowOverRide: (setting.MasterEmail ? setting.MasterEmail.AllowOverride : true),
                emailEnabled: (setting.MasterEmail ? setting.MasterEmail.EmailEnabled : true),
                emailLogUrl: (setting.MasterEmail ? setting.MasterEmail.EmailLogUrl : null),
                accessToken: (setting.MasterEmail ? setting.MasterEmail.AccesToken : null),
                emailUrl: (setting.MasterEmail ? setting.MasterEmail.EmailUrl : null),
                clientId: (setting.MasterEmail ? setting.MasterEmail.ClientId : null),
            },
            masterGeneralSetting: {
                isActive: (setting.MasterGeneralSetting ? setting.MasterGeneralSetting.IsActive : true),
                portalSessionTimeout: (setting.MasterGeneralSetting ? setting.MasterGeneralSetting.PortalSessionTimeout : null),
                appSessionTimeout: (setting.MasterGeneralSetting ? setting.MasterGeneralSetting.AppSessionTimeout : null),
            },
        };

        return masterSettingResponse;
    }

}
