import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Response, Product } from '../../models';
import { ErrorConstants, ApiDictionary, Resources, SuccessConstants } from 'src/app/constants';
import { HttpErrorResponse } from '@angular/common/http';
@Injectable()
export class ProductService implements OnInit {
    constructor(private apiService: ApiService) { }
    ngOnInit() {

    }

    saveProduct(product: Product): Observable<any> {
        let apiUrl = ApiDictionary.saveProduct.url;
        apiUrl = Resources.saveProduct + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, product).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    getProductsBySearchCriteriaForAdmin(searchObj: any): Observable<any> {
        let apiUrl = ApiDictionary.getProducts.url;
        apiUrl = Resources.getProducts + apiUrl;
        const observable = new Observable((observer) => {
            const request = {
                ProductSearchFilter: {
                    ProductName: searchObj.name,
                    CategoryId: searchObj.categoryId,
                    BrandId: searchObj.brandId,
                    ProductOperationSearchType: 'Contains',
                    ExpiryDate: searchObj.expiryDate.toDateString(),
                    CategoryIds: searchObj.categoryIds ? searchObj.categoryIds : [],
                    BrandIds: searchObj.brandIds ? searchObj.brandIds : [],
                    IsNewProduct: searchObj.isNewProduct || false
                },
                PriceSearchFilter: {
                    MinValue: searchObj.minPrice,
                    MaxValue: searchObj.maxPrice
                },
                PaginationFilter: {
                    PageIndex: searchObj.pageIndex, PageSize: searchObj.pageSize, SortingColumn: searchObj.sortBy,
                    SortDirection: searchObj.sortDirection
                },
                IsActive: searchObj.status
            };
            this.apiService.postData(apiUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: {
                            'productData': this.populateAdminProductList(result.Products),
                            'totalCount': result.TotalCount
                        }, error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    getProductsBySearchCriteriaForUser(searchObj: any): Observable<any> {
        let apiUrl = ApiDictionary.getUserProducts.url;
        apiUrl = Resources.getUserProducts + apiUrl;
        const observable = new Observable((observer) => {
            const request = {
                ProductSearchFilter: {
                    ProductName: searchObj.name,
                    CategoryId: searchObj.categoryId,
                    BrandId: searchObj.brandId,
                    ProductOperationSearchType: searchObj.productOperationSearchType,
                    ExpiryDate: searchObj.expiryDate,
                    CategoryIds: searchObj.categoryIds ? searchObj.categoryIds : [],
                    BrandIds: searchObj.brandIds ? searchObj.brandIds : [],
                    IsNewProduct: searchObj.isNewProduct,
                    ProductId: searchObj.productId
                },
                PriceSearchFilter: {
                    MinValue: searchObj.minPrice,
                    MaxValue: searchObj.maxPrice
                },
                PaginationFilter: {
                    PageIndex: searchObj.pageIndex, PageSize: searchObj.pageSize, SortingColumn: searchObj.sortBy,
                    SortDirection: searchObj.sortDirection
                },
                IsActive: searchObj.status,
                CartId: searchObj.cartId
            };
            this.apiService.postData(apiUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: {
                            'productData': this.populateNonAdminProductList(result.Products),
                            'totalCount': result.TotalCount
                        }, error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateAdminProductList(products: Array<any>) {
        const productsList = [];
        for (let index = 0; index < products.length; index++) {
            const product: any = {};
            product.id = products[index].ProductId;
            product.name = products[index].ProductName;
            product.brand = products[index].Brand;
            product.category = products[index].Category;
            product.isActive = products[index].IsActive;
            product.media = [];
            for (let count = 0; count < products[index].Media.length; count++) {
                product.media.push(products[index].Media[count]);
            }
            product.variants = this.populateVariants(products[index]);
            productsList.push(product);
        }
        return productsList;
    }

    private populateNonAdminProductList(products: Array<any>) {
        const productsList = [];
        for (let index = 0; index < products.length; index++) {
            const product: any = {};
            product.id = products[index].ProductId;
            product.name = products[index].ProductName;
            product.brand = products[index].Brand;
            product.category = products[index].Category;
            product.longDescription = products[index].LongDescription;
            product.description = products[index].Description;
            product.isActive = products[index].IsActive;
            product.media = [];
            product.qty = 0;
            for (let count = 0; count < products[index].Media.length; count++) {
                product.media.push(products[index].Media[count]);
            }
            product.variants = this.populateVariants(products[index]);
            product.inCart = 'Add';
            product.skuId = 0;
            product.itemCartId = null;
            product.optinPrice = 0;
            if (product.variants.length > 0) {
                product.defaultPrice = product.variants[0].price;
                product.defaultVariant = product.variants[0].uom;
                product.skuId = product.variants[0].skuid;
                product.qty = product.variants[0].qty;
                product.itemCartId = product.variants[0].cartitemid || null;
                product.inCart = product.variants[0].iscartitem ? 'Remove' : 'Add';
            } else {
                product.defaultPrice = 0;
                product.defaultVariant = null;
            }
            productsList.push(product);
        }
        return productsList;
    }

    private populateVariants(product: any) {
        const variants = [];
        for (let count = 0; count < product.Variants.length; count++) {
            const element = product.Variants[count];
            const variant = {};
            // tslint:disable-next-line:forin
            for (const key in element) {
                variant[key.toLowerCase()] = element[key];
            }
            variants.push(variant);
        }
        return variants;
    }

    updateProduct(product: Product): Observable<any> {
        let apiUrl = ApiDictionary.updateProduct.url;
        apiUrl = Resources.updateProduct + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.updateData(apiUrl, product).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: product.ProductName + SuccessConstants.Messages.Save_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    deleteProduct(id: number, status: boolean, name: string): Observable<any> {
        let apiUrl = ApiDictionary.deleteProduct.url;
        apiUrl = Resources.deleteProduct + apiUrl + '/' + id + '/' + (!status);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: name + (!status ? SuccessConstants.Messages.Activate_Success :
                            SuccessConstants.Messages.Deactivate_Success),
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    getAdminProductById(id: number): Observable<any> {
        let apiUrl = ApiDictionary.getProductDetails.url;
        apiUrl = Resources.getProductDetails + apiUrl + '/' + id;
        const options = this.apiService.BindParamsToUrl(apiUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(options).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateProductDetails(result), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateProductDetails(product: any) {
        const productInfo: any = {};
        productInfo.id = product.ProductId;
        productInfo.name = product.ProductName;
        productInfo.categoryId = product.CategoryId;
        productInfo.brandId = product.BrandId;
        productInfo.description = product.Description;
        productInfo.longDescription = product.LongDescription;
        productInfo.upc = product.UPC;
        productInfo.isActive = product.IsActive;
        productInfo.startDate = product.StartDate ?
            product.StartDate.indexOf('T') ? new Date(product.StartDate.substr(0, product.StartDate.indexOf('T')))
                : new Date(product.StartDate) : null;
        productInfo.endDate = product.EndDate ?
            product.EndDate.indexOf('T') ? new Date(product.EndDate.substr(0, product.EndDate.indexOf('T')))
                : new Date(product.EndDate) : null;
        productInfo.medias = [];
        productInfo.productSkus = [];
        productInfo.productSkuValues = [];
        for (let index = 0; index < product.ProductMedias.length; index++) {
            const element = product.ProductMedias[index];
            productInfo.medias.push(element.MediaId);
        }
        for (let index = 0; index < product.ProductSKUs.length; index++) {
            const element = product.ProductSKUs[index];
            const sku = {
                skuId: element.SkuId,
                sku: element.Sku,
                mrp: element.MRP,
                isActive: element.IsActive,
                startDate: element.StartDate ?
                    element.StartDate.indexOf('T') ? new Date(element.StartDate.substr(0, element.StartDate.indexOf('T')))
                        : new Date(element.StartDate) : null,
                endDate: element.EndDate ?
                    element.EndDate.indexOf('T') ? new Date(element.EndDate.substr(0, element.EndDate.indexOf('T')))
                        : new Date(element.EndDate) : null
            };
            productInfo.productSkus.unshift(sku);
        }
        for (let index = 0; index < product.ProductSKUValues.length; index++) {
            const element = product.ProductSKUValues[index];
            const skuValue = {
                optionId: element.OptionId,
                valueId: element.ValueId,
                skuId: element.SkuId,
                sku: element.Sku,
                optionName: element.OptionName,
                valueName: element.ValueName,
                id: index + 1
            };
            productInfo.productSkuValues.unshift(skuValue);
        }
        return productInfo;
    }

    getProductNames(searchObj: any): Observable<any> {
        let apiUrl = ApiDictionary.getProductNames.url;
        apiUrl = Resources.getProductNames + apiUrl;
        const observable = new Observable((observer) => {
            const request = {
                ExpiryDate: searchObj.expiryDate,
                IsActive: searchObj.isActive
            };
            this.apiService.postData(apiUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateProductNames(result.ProductNames), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateProductNames(productData: Array<any>): Array<any> {
        const products = [];
        for (let index = 0; index < productData.length; index++) {
            const element = {
                name: productData[index].Name,
                brand: productData[index].Brand,
                imageURL: productData[index].ImageURL ? Resources.getImage + productData[index].ImageURL : ''
            };
            products.push(element);
        }
        return products;
    }

    getProductImagesById(id: number): Observable<any> {
        let apiUrl = ApiDictionary.getProductImagesById.url;
        apiUrl = Resources.getProductImagesById + apiUrl + '/' + id;
        const options = this.apiService.BindParamsToUrl(apiUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(options).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateProductDetailImages(result.Images), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateProductDetailImages(images: Array<string>): Array<string> {
       const productImages: Array<string> = [];
       for (let index = 0; index < images.length; index++) {
           let element = images[index];
           element = Resources.getImage + element;
           productImages.push(element);
       }
       return productImages;
    }
}
