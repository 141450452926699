import { Injectable } from '@angular/core';
import { PermissionSet, ApiDictionary, Resources, ErrorConstants, SuccessConstants } from '../../constants';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Response, UserPermission, Permission, SortByPermissions, SavePermission } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class PermissionService {

    constructor(private apiService: ApiService) { }

    private getRolePermissions(role: string) {
        let permissionSet = [];
        switch (role.toLowerCase()) {
            case 'superadmin': permissionSet = PermissionSet.SuperAdminPermissionset;
                break;
            case 'backendadmin':
            case 'admin': permissionSet = PermissionSet.BackendAdminPermissionset;
                break;
            case 'operator': permissionSet = PermissionSet.OperatorPermissionset;
                break;
            default: break;
        }
        return permissionSet;
    }

    hasPermission(module: string, permission: string, permissionSet: any): boolean {
        let result = false;
        if (permissionSet.length > 0) {
            for (let index = 0; index < permissionSet.length; index++) {
                const permissionItem = permissionSet[index];
                if (permissionItem.name.toLowerCase() === module) {
                    result = permissionItem[permission];
                    break;
                }
            }
        }
        return result;
    }

    getNavigationMenus(permissionSet: any) {
        if (permissionSet.length > 0) {
            permissionSet = permissionSet.filter((element) => {
                return element.canMenuItem;
            });
        }
        return permissionSet || [];
    }

    getMenusByUserIdDeviceType(userId: string, deviceType: string): Observable<any> {
        let permissionSetUrl = ApiDictionary.getPermissionSet.url;
        permissionSetUrl = Resources.getPermissionSet + permissionSetUrl;
        permissionSetUrl = permissionSetUrl + '/' + userId + '/' +
            (deviceType === 'mobile' ? true : false);
        const param = this.apiService.BindParamsToUrl(permissionSetUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(param).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const response: Response = {
                        failure: false, success: true,
                        result: this.buildPermisisonList(result.PermissionSet), error: null
                    };
                    observer.next(response);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    buildPermisisonList(permissionSet: Array<any>) {
        const permissions = [];
        permissionSet.forEach(element => {
            const permission: UserPermission = {
                canCreate: element.CanCreate,
                canDelete: element.CanDelete,
                canMenuItem: element.CanMenuItem,
                canUpdate: element.CanUpdate,
                canView: element.CanView,
                icon: element.WebIcon,
                link: [element.WebUrl],
                sortBy: element.SortBy,
                name: element.Name,
                navigatePage: element.NavigatePage,
                src: element.AppIcon,
                title: element.Name
            };
            permissions.push(permission);
        });
        return permissions;
    }

    getPermissionBySearchCriteria(searchObj: any): Observable<any> {
        let permissionUrl = ApiDictionary.getPermissions.url;
        permissionUrl = Resources.getPermissions + permissionUrl;
        const observable = new Observable((observer) => {
            const request = { Name: searchObj.name, IsActive: searchObj.status };
            this.apiService.postData(permissionUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const response: Response = {
                        failure: false, success: true,
                        result: this.buildPermissions(result.Permissions), error: null
                    };
                    observer.next(response);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private buildPermissions(permissions: Array<any>) {
        const permissionList = [];
        permissions.forEach(element => {
            const permission = {
                canCreate: element.CanCreate,
                canDelete: element.CanDelete,
                canMenuItem: element.CanMenuItem,
                canUpdate: element.CanUpdate,
                canView: element.CanView,
                webIcon: element.WebIcon,
                webLink: element.WebUrl,
                sortBy: element.SortBy,
                name: element.Name,
                appLink: element.NavigatePage,
                appIcon: element.AppIcon,
                id: element.Id,
                notify: element.Notify
            };
            permissionList.push(permission);
        });
        return permissionList;
    }

    getSortbyPermissions(): Observable<any> {
        let permissionUrl = ApiDictionary.getSortByPermissions.url;
        permissionUrl = Resources.getSortByPermissions + permissionUrl;
        const param = this.apiService.BindParamsToUrl(permissionUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(param).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const response: Response = {
                        failure: false, success: true,
                        result: this.buildSortByPermissions(result.SortByPermissions), error: null
                    };
                    observer.next(response);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    getSortbyPermissionStatus(): Observable<any> {
        let permissionUrl = ApiDictionary.getSortbyPermissionStatus.url;
        permissionUrl = Resources.getSortbyPermissionStatus + permissionUrl;
        const param = this.apiService.BindParamsToUrl(permissionUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(param).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const response: Response = {
                        failure: false, success: true,
                        result: this.buildSortByPermissions(result.SortByPermissions), error: null
                    };
                    observer.next(response);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private buildSortByPermissions(permissions: Array<any>) {
        const permissionList = [];
        permissions.forEach(element => {
            const permission: SortByPermissions = {
                sortBy: element.SortBy,
                name: element.Name,
                id: element.Id
            };
            permissionList.push(permission);
        });
        return permissionList;
    }

    savePermission(saveObject: any): Observable<any> {
        let permissionUrl = ApiDictionary.savePermission.url;
        permissionUrl = Resources.savePermission + permissionUrl;
        const observable = new Observable((observer) => {
            const reqBody = this.buildSaveObject(saveObject);
            if (reqBody.length === 0) {
                const res: Response = {
                    failure: true, success: false, error: 'Atleast add read permission'
                };
                observer.next(res);
            } else {
                this.apiService.postData(permissionUrl, { 'SavePermissions': reqBody }).subscribe((result: any) => {
                    if (result instanceof HttpErrorResponse) {
                        const res: Response = {
                            failure: true, success: false,
                            error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                        };
                        observer.next(res);
                    } else {
                        const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                        observer.next(res);
                    }
                }, (error) => {
                    const res: Response = {
                        failure: true, success: false, error: null
                    };
                    if (!error.Code && error.Code.indexOf('400') > -1) {
                        res.error = error.Message;
                    } else {
                        res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                    }
                    observer.next(res);
                });
            }
        });
        return observable;
    }
    buildSaveObject(saveObject: any): any {
        const permissions = Array<SavePermission>();
        permissions.push({
            Action: 'Get',
            Resource: saveObject.name, WebIcon: saveObject.webIcon,
            AppIcon: saveObject.appIcon,
            AppLink: saveObject.appLink,
            CanMenuItem: saveObject.canMenuItem,
            Notify: saveObject.notify,
            SortBy: saveObject.sortBy,
            IsActive: saveObject.canView,
            WebLink: saveObject.webLink,
            Id: saveObject.id || 0
        });
        permissions.push({
            Action: 'Post',
            Resource: saveObject.name,
            WebIcon: '',
            AppIcon: '',
            AppLink: '',
            CanMenuItem: false,
            Notify: saveObject.notify,
            SortBy: 0,
            WebLink: '',
            Id: 0,
            IsActive: saveObject.canCreate
        });
        permissions.push({
            Action: 'Put',
            Resource: saveObject.name,
            WebIcon: '',
            AppIcon: '',
            AppLink: '',
            CanMenuItem: false,
            Notify: saveObject.notify,
            SortBy: 0,
            WebLink: '',
            Id: 0,
            IsActive: saveObject.canUpdate
        });
        permissions.push({
            Action: 'Delete',
            Resource: saveObject.name,
            WebIcon: '',
            AppIcon: '',
            AppLink: '',
            CanMenuItem: false,
            Notify: saveObject.notify,
            SortBy: 0,
            WebLink: '',
            Id: 0,
            IsActive: saveObject.canDelete
        });
        return permissions;
    }

    updatePermission(saveObject: any): Observable<any> {
        let permissionUrl = ApiDictionary.updatePermission.url;
        permissionUrl = Resources.updatePermission + permissionUrl;
        const observable = new Observable((observer) => {
            const reqBody = this.buildSaveObject(saveObject);
            if (reqBody.length === 0) {
                const res: Response = {
                    failure: true, success: false, error: 'Atleast add read permission'
                };
                observer.next(res);
            } else {
                this.apiService.updateData(permissionUrl, { 'SavePermissions': reqBody }).subscribe((result: any) => {
                    if (result instanceof HttpErrorResponse) {
                        const res: Response = {
                            failure: true, success: false,
                            error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                        };
                        observer.next(res);
                    } else {
                        const res: Response = {
                            failure: false, error: null, success: true,
                            result: saveObject.name + SuccessConstants.Messages.Save_Success
                        };
                        observer.next(res);
                    }
                }, (error) => {
                    const res: Response = {
                        failure: true, success: false, error: null
                    };
                    if (!error.Code && error.Code.indexOf('400') > -1) {
                        res.error = error.Message;
                    } else {
                        res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                    }
                    observer.next(res);
                });
            }
        });
        return observable;
    }

    deletePermission(name: string, status: boolean): Observable<any> {
        let permissionUrl = ApiDictionary.deletePermission.url;
        permissionUrl = Resources.deletePermission + permissionUrl + '/' + name + '/' + (!status);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(permissionUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: name + (!status ? SuccessConstants.Messages.Activate_Success : SuccessConstants.Messages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
}


