import { Injectable } from '@angular/core';
import { ApiDictionary, Resources, ErrorConstants, SuccessConstants } from '../../constants';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Response, Role } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class RoleService {
    constructor(private apiService: ApiService) { }
    getRolesBySearchCriteria(name: string, status: boolean) {
        let apiUrl = ApiDictionary.getRoles.url;
        apiUrl = Resources.getRoles + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, { Name: name.trim().toLowerCase(), IsActive: status }).subscribe((response: any) => {
                if (response instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[response.status]
                    };
                    observer.next(res);
                }
                if (response.Roles && response.Roles.length >= 0) {
                    const successRes: Response = {
                        failure: false, success: true,
                        result: this.buildResponseModel(response.Roles), error: null
                    };
                    observer.next(successRes);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: ErrorConstants.Api[error.status || error.Status || error.Code]
                };
                observer.next(res);
            });
        });
        return observable;
    }

    private buildResponseModel(roles: Array<any>) {
        const roleList = [];
        for (let index = 0; index < roles.length; index++) {
            roleList.push({ id: roles[index].Id, name: roles[index].Name, isActive: roles[index].IsActive });
        }
        return roleList;
    }

    saveRole(role: Role) {
        let apiUrl = ApiDictionary.saveRole.url;
        apiUrl = Resources.saveRole + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, role).subscribe((response) => {
                if (response instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: response.status === 400 ? response.error : ErrorConstants.Api[response.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null, result: role.Name + SuccessConstants.Messages.Save_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false,
                    error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.status || error.Status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    updateRole(role: Role) {
        let apiUrl = ApiDictionary.updateRole.url;
        apiUrl = Resources.updateRole + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.updateData(apiUrl, role).subscribe((response) => {
                if (response instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: response.status === 400 ? response.error : ErrorConstants.Api[response.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null, result: role.Name + SuccessConstants.Messages.Save_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.status || error.Status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    deleteRole(role: any) {
        let apiUrl = ApiDictionary.deleteRole.url;
        apiUrl = Resources.deleteRole + apiUrl + '/' + role.id + '/' + !role.isActive;
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((response) => {
                if (response instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: response.status === 400 ? response.error : ErrorConstants.Api[response.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: role.name + (!role.isActive ? SuccessConstants.Messages.Activate_Success :
                            SuccessConstants.Messages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
}
