import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  AuthService, ProfileService, UserService, PermissionService, ApiService, RoleService,
  RolePermissionService, CatalogService, BrandService, CategoryService, MediaService,
  ProductOptionService, ProductService, UserRoleService, UserNotificationService,
  MasterSettingService, NotificationTemplatesService, AnnouncementService,
  SubscriptionService,  NotificationService, OptinCartService, FaqService, PromotionService
} from './providers';
import { NgIdleModule } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import {
  MatProgressSpinnerModule, MatDialogModule, ErrorStateMatcher, MatButtonModule
} from '@angular/material';
import { ProgressBarComponent, ConfirmComponent, AlertComponent, SessionPopupComponent } from './utils/';
import { LoaderService, ProgressInfoService, AuthGuard, ModalPopupService, SharedService, RoutingService } from './providers';
import { UsersComponent } from './admin/users/users.component';
import {
  NbThemeModule, NbSidebarModule, NbLayoutModule, NbSidebarService, NbActionsModule,
  NbMenuModule, NbContextMenuModule, NbUserModule, NbSpinnerModule
} from '@nebular/theme';
import { SetPasswordComponent } from './core/set-password/set-password.component';
import { UtilModule } from './utils/';
import { CoreModule } from './core/core.module';


export class ErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

@NgModule({
  declarations: [
    AppComponent,
    ProgressBarComponent,
    UsersComponent,
    ConfirmComponent,
    AlertComponent,
    SessionPopupComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NoopAnimationsModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    NbSidebarModule,
    NbLayoutModule,
    NbActionsModule,
    NbMenuModule.forRoot(),
    NbContextMenuModule,
    NbUserModule,
    NbSpinnerModule,
    NgIdleModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    CoreModule,
    UtilModule
  ],
  providers: [
    NbSidebarService,
    LoaderService,
    ApiService,
    AuthService,
    UserService,
    AuthGuard,
    ProfileService,
    PermissionService,
    ModalPopupService,
    RoutingService,
    SharedService,
    RoleService,
    RolePermissionService,
    UserRoleService,
    UserNotificationService,
    BrandService,
    CatalogService,
    CategoryService,
    MediaService,
    MasterSettingService,
    ProductOptionService,
    ProductService,
    NotificationTemplatesService,
    AnnouncementService,
    SubscriptionService,
    FaqService,
    PromotionService,
    NotificationService,
    OptinCartService,
     { provide: ErrorStateMatcher, useClass: ErrorMatcher },
    { provide: HTTP_INTERCEPTORS, useClass: ProgressInfoService, multi: true }
  ],
  entryComponents: [
    ProgressBarComponent,
    AlertComponent,
    ConfirmComponent,
    SetPasswordComponent,
    SessionPopupComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
