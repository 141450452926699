import { Injectable } from '@angular/core';
import { HttpParams, HttpRequest, HttpClient } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Response } from '../models';

@Injectable()
export class ApiService {
    constructor(private http: HttpClient) { }
    BindParamsToUrl(requestTemplate: any, modelData: any): HttpRequest<any> {
        const url = requestTemplate.url ? requestTemplate.url : requestTemplate;
        const jsonparams = requestTemplate.params;
        let search = new HttpParams();
        if (modelData) {
            Object.keys(jsonparams).forEach(element => {
                search = search.set(element, modelData[element]);
            });
        }
        const options = new HttpRequest<any>('GET', url, { params: search });
        return options;
    }
    getData(options: HttpRequest<any>): Observable<Object> {
        const observable = new Observable((observer) => {
            this.http.get(options.url, {
                params: options.params
            }).subscribe(response => observer.next(response), error => observer.next(error));
        });
        return observable;
    }

    postData(url: string, body: object): Observable<Object> {
        const observable = new Observable((observer) => {
            this.http.post(url, body).subscribe((response) => {
                observer.next(response);
            }, (error) => {
                observer.next(error);
            });
        });
        return observable;
    }

    updateData(url: string, body: object): Observable<Object> {
        const observable = new Observable((observer) => {
            this.http.put(url, body).subscribe((response) => {
                observer.next(response);
            }, (error) => {
                observer.next(error);
            });
        });
        return observable;
    }

    deleteData(url: string): Observable<Object> {
        const observable = new Observable((observer) => {
            this.http.delete(url).subscribe((response) => {
                observer.next(response);
            }, (error) => {
                observer.next(error);
            });
        });
        return observable;
    }
}

