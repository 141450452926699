import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService, RoutingService, SharedService, ModalPopupService,
     AuthService, ProfileService, PermissionService, UserService } from '../../providers';
import { Response } from '../../models';
import { SetPasswordComponent } from '../set-password/set-password.component';
import { AlertComponent } from '../../utils';


@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent {
    accountForm: FormGroup;
    errorMsg = '';
    constructor(private authService: AuthService,
        private profileService: ProfileService,
        private permissionService: PermissionService,
        private router: Router,
        private loaderService: LoaderService,
        private fb: FormBuilder,
        private sharedService: SharedService,
        private routingService: RoutingService,
        private modelPopupService: ModalPopupService,
        private userService: UserService) {
        this.accountForm = this.fb.group({
            userName: new FormControl('', Validators.required),
            password: new FormControl('', [Validators.required, Validators.minLength(6)])
        });
    }

    login(): void {
        this.errorMsg = '';
        console.clear();
        if (this.accountForm.valid) {
            const mobileRegex = new RegExp('^[6-9][0-9]{9}$');
            let userName: string = this.accountForm.get('userName').value;
            if (mobileRegex.test(userName)) {
                userName = '+91' + userName;
            }
            const password = this.accountForm.get('password').value;
            this.accountForm.disable();
            const dialogRef = this.loaderService.start();
            this.authService.signIn(userName, password).subscribe(response => {
                if (response.success) {
                    this.profileService.getProfileUser().subscribe((res) => {
                        if (res.success) {
                            if (res.result.role && res.result.role.toLowerCase() !== 'user') {
                                if (res.result.preferred_username === userName || ('+91' + res.result.phone_number) === userName) {
                                    this.sharedService.userName = res.result.preferred_username;
                                    this.userService.saveUserDetails(res.result, btoa(password), 0.00, 0.00)
                                        .subscribe((updateResponse: Response) => {
                                            if (updateResponse.failure) {
                                                console.log(updateResponse.error);
                                            } else {
                                                this.permissionService.getMenusByUserIdDeviceType
                                                    (this.accountForm.get('userName').value, 'web')
                                                    .subscribe((permissions) => {
                                                        this.loaderService.close(dialogRef);
                                                        this.sharedService.permissionList = permissions.result;
                                                        const url = this.routingService.defaultRoutebyRole(res.result.role);
                                                        this.router.navigate([url]);
                                                    }, (err) => {
                                                        this.errorMsg = '';
                                                        this.loaderService.close(dialogRef);
                                                        this.modelPopupService.openPopup<AlertComponent>
                                                            (AlertComponent, { message: err.error });
                                                        this.accountForm.enable();
                                                    });
                                            }
                                        });
                                } else {
                                    this.profileService.clearProfile();
                                    this.loaderService.close(dialogRef);
                                    this.errorMsg = 'User Name / Password not found';
                                    this.accountForm.enable();
                                }
                            } else {
                                this.profileService.clearProfile();
                                this.loaderService.close(dialogRef);
                                this.errorMsg = 'You do not have enough permission to access this website';
                                this.accountForm.enable();
                            }
                        } else {
                            this.profileService.clearProfile();
                            this.loaderService.close(dialogRef);
                            this.accountForm.enable();
                            this.errorMsg = 'You do not have enough permission to access this website';
                        }
                    }, (err) => {
                        console.log(err);
                        this.loaderService.close(dialogRef);
                    });
                } else if (response.failure && (typeof response.error === 'string')) {
                    this.loaderService.close(dialogRef);
                    if (response.error.indexOf('reset') >= 0) {
                        const data = {
                            userName: userName,
                            message: response.error,
                            formType: 'ResetPassword'
                        };
                        this.modelPopupService.openPopup<SetPasswordComponent>(SetPasswordComponent, data).afterClosed()
                            .subscribe((modalResponse: boolean) => {
                                this.accountForm.enable();
                                if (modalResponse) {
                                    this.accountForm.reset();
                                }
                            });
                    } else {
                        this.errorMsg = response.error;
                        this.accountForm.enable();
                    }
                } else if (response.failure) {
                    this.loaderService.close(dialogRef);
                    this.accountForm.enable();
                    console.log(response.error);
                    this.modelPopupService.openPopup(AlertComponent, { message: 'Something Went Wrong! Please Try Again' });
                }
            });
        }
    }

    forgotPassword(): void {
        const data = {
            userName: '',
            message: 'Set New Password',
            formType: 'ForgotPassword'
        };
        this.modelPopupService.openPopup<SetPasswordComponent>(SetPasswordComponent, data);
    }
}
