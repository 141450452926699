import { Injectable } from '@angular/core';
import { ApiDictionary, Resources, ErrorConstants, SuccessConstants } from '../../constants';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Catalog, Response } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';
@Injectable()
export class CatalogService {
    constructor(private apiService: ApiService) { }

    saveCatalog(catalog: Catalog): Observable<any> {
        let apiUrl = ApiDictionary.saveCatalog.url;
        apiUrl = Resources.saveCatalog + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, catalog).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    searchCatalog(searchObj: any): Observable<any> {
        let catalogUrl = ApiDictionary.getCatalogSearch.url;
        catalogUrl = Resources.getCatalogSearch + catalogUrl;
        const observable = new Observable((observer) => {
            const request = { Name: searchObj.name, IsActive: searchObj.status,
                 ExpiryDate: searchObj.expiryDate.toDateString() };
            this.apiService.postData(catalogUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateCatalogList(result.Catalogs), error: null 
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    searchUserCatalog(searchObj: any): Observable<any> {
        let catalogUrl = ApiDictionary.getUserCatalogSearch.url;
        catalogUrl = Resources.getUserCatalogSearch + catalogUrl;
        const observable = new Observable((observer) => {
            const request = { Name: searchObj.name, IsActive: searchObj.status, ExpiryDate: searchObj.expiryDate };
            this.apiService.postData(catalogUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateCatalogList(result.Catalogs), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateCatalogList(catalogs: any): any {
        const catalogList = [];
        for (let index = 0; index < catalogs.length; index++) {
            const catalog = {
                id: catalogs[index].Id,
                name: catalogs[index].Name,
                startDate: catalogs[index].StartDate ?
                    (catalogs[index].StartDate.indexOf('T') > 0
                        ? new Date(catalogs[index].StartDate.substring(0, catalogs[index].StartDate.indexOf('T')))
                        : new Date(catalogs[index].StartDate)) : null,
                endDate: catalogs[index].EndDate ?
                    (catalogs[index].EndDate.indexOf('T') > 0
                        ? new Date(catalogs[index].EndDate.substring(0, catalogs[index].EndDate.indexOf('T')))
                        : new Date(catalogs[index].EndDate)) : null,
                isActive: catalogs[index].IsActive,
                logo: catalogs[index].Image ? catalogs[index].Image : '',
                thumbnail: catalogs[index].Thumbnail ? catalogs[index].Thumbnail : '',
                categoryIds: catalogs[index].CategoryIds
            };
            catalogList.push(catalog);
        }
        return catalogList;
    }

    updateCatalog(catalog: Catalog): Observable<any> {
        let apiUrl = ApiDictionary.updateCatalog.url;
        apiUrl = Resources.updateCatalog + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.updateData(apiUrl, catalog).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: catalog.Name + SuccessConstants.Messages.Save_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    deleteCatalog(id: number, status: boolean, name: string): Observable<any> {
        let apiUrl = ApiDictionary.deleteCatalog.url;
        apiUrl = Resources.deleteCatalog + apiUrl + '/' + id + '/' + (!status);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: name + (!status ? SuccessConstants.Messages.Activate_Success : SuccessConstants.Messages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
}
