export interface GridOptions {
    serverSideSorting?: boolean;
    serverSidePagination?: boolean;
    serverSideFiltering?: boolean;
    enablePagination?: boolean;
    enableSorting?: boolean;
    showEdit?: boolean;
    showDelete?: boolean;
    showView?: boolean;
    showMasterCart?: boolean;
    masterCartCallback?: Function;
    showExtraActions?: boolean;
    serverSortingCallback?: Function;
    serverSidePaginationCallback?: Function;
    editCallback?: Function;
    deleteCallback?: Function;
    viewCallback?: Function;
    extraActions?: Array<{name: string, action: Function, cssClass: string}>;
    dataBind?: Function;
}

export interface DisplayColumns {
    headerName: string;
    type: string;
    columnName: string;
}

export interface OMDatasource {
    displayColumns?: DisplayColumns[];
    options?: GridOptions;
    source?: Array<any>;
    count?: number;
    icon: string;
    eventType?: string;
}

export enum EventTypes {
    Pagination = 'pagination', DataBind = 'databind', ItemChange = 'itemchange', Sorting = 'sorting'
}
