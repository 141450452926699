import { Injectable } from '@angular/core';
import { ApiDictionary, Resources, ErrorConstants, SuccessConstants } from '../../constants';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Response, Announcement } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable()
export class AnnouncementService {
    constructor(private apiService: ApiService) { }

    saveAnnouncement(announcement: Announcement): Observable<any> {
        let apiUrl = ApiDictionary.saveAnnouncement.url;
        apiUrl = Resources.saveAnnouncement + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.postData(apiUrl, announcement).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    searchAnnouncement(searchObj: any): Observable<any> {
        let apiUrl = ApiDictionary.getAnnouncementSearch.url;
        apiUrl = Resources.getAnnouncementSearch + apiUrl;
        const observable = new Observable((observer) => {
            const request = {
                NotificationType: searchObj.notificationType, PriyorityType: searchObj.priyorityType,
                EventType: searchObj.eventType, SentOn: searchObj.sentOn.toDateString() ,
                RoleId: searchObj.roleId,
                User: searchObj.user, Store: searchObj.store, TemplateType: searchObj.templateType,
                SendManually: searchObj.sendManually, IsActive: searchObj.isActive
            };
            this.apiService.postData(apiUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false, error: ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateAnnouncementList(result.NotificationJobs), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateAnnouncementList(announcements: any): any {
        const announcementList = [];
        for (let index = 0; index < announcements.length; index++) {
            const announcement = {
                id: announcements[index].JobId,
                notificationType: announcements[index].NotificationType,
                notificationTypeName: announcements[index].NotificationTypeName,
                priyority: announcements[index].PriyorityId,
                priorityName: announcements[index].PriyorityName,
                eventId: announcements[index].EventId,
                eventName: announcements[index].EventName,
                from: announcements[index].From,
                fromName: announcements[index].FromName,
                sentTries: announcements[index].SentTries,
                sentOnUtc: announcements[index].SentOnUtc ?
                    (announcements[index].SentOnUtc.indexOf('T') > 0
                        ? new Date(announcements[index].SentOnUtc.substring(0, announcements[index].SentOnUtc.indexOf('T')))
                        : new Date(announcements[index].SentOnUtc)) : null,

                dontSendBeforeDateUtc: announcements[index].DontSendBeforeDateUtc,
                sendManually: announcements[index].SendManually,

                createdDate: announcements[index].CreatedDate ?
                    (announcements[index].CreatedDate.indexOf('T') > 0
                        ? new Date(announcements[index].CreatedDate.substring(0, announcements[index].CreatedDate.indexOf('T')))
                        : new Date(announcements[index].CreatedDate)) : null,
                notificationTemplateId: announcements[index].NotificationTemplateId,
                templateName: announcements[index].TemplateName,
                userId: announcements[index].UserId,
                roleId: announcements[index].RoleId,
                storeId: announcements[index].StoreId,
                isActive: announcements[index].IsActive,
            };
            announcementList.push(announcement);
        }
        return announcementList;
    }
    searchNotificationTypes(): Observable<any> {
        let typeUrl = ApiDictionary.getNotificationTypeSearch.url;
        typeUrl = Resources.getNotificationTypeSearch + typeUrl;
        const options = this.apiService.BindParamsToUrl(typeUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(options).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateNotificationTypes(result.Types), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateNotificationTypes(types: any): any {
        const typeList = [];
        for (let index = 0; index < types.length; index++) {
            const store = {
                name: types[index].Key,
                id: types[index].Value,
            };
            typeList.push(store);
        }
        return typeList;
    }
    searchEventTypes(): Observable<any> {
        let typeUrl = ApiDictionary.getEventTypeSearch.url;
        typeUrl = Resources.getEventTypeSearch + typeUrl;
        const options = this.apiService.BindParamsToUrl(typeUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(options).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateEventTypes(result.Types), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateEventTypes(types: any): any {
        const typeList = [];
        for (let index = 0; index < types.length; index++) {
            const store = {
                name: types[index].Key,
                id: types[index].Value,
            };
            typeList.push(store);
        }
        return typeList;
    }
    searchPriorityTypes(): Observable<any> {
        let typeUrl = ApiDictionary.getPriorityTypeSearch.url;
        typeUrl = Resources.getEventTypeSearch + typeUrl;
        const options = this.apiService.BindParamsToUrl(typeUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(options).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populatePriorityTypes(result.Types), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: ErrorConstants.Api[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populatePriorityTypes(types: any): any {
        const typeList = [];
        for (let index = 0; index < types.length; index++) {
            const store = {
                name: types[index].Key,
                id: types[index].Value,
            };
            typeList.push(store);
        }
        return typeList;
    }
    updateAnnouncement(announcement: Announcement): Observable<any> {
        let apiUrl = ApiDictionary.updateAnnouncement.url;
        apiUrl = Resources.updateAnnouncement + apiUrl;
        const observable = new Observable((observer) => {
            this.apiService.updateData(apiUrl, announcement).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : ErrorConstants.Api[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: announcement + SuccessConstants.Messages.Save_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = ErrorConstants.Api[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
}
