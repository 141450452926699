import { Injectable, OnDestroy } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { NavigationStart, Router } from '@angular/router';
import { ModalPopupService } from './modalpopup.service';
import { SessionPopupComponent } from '../utils/components/sessionPopup/sessionPopup.component';
import { environment } from '../../environments/environment';
import { SharedService } from './shared.Service';
import { AuthService } from './user/auth.service';
import { PermissionService } from './permission/permission.service';
import { ProfileService } from './user/profile.service';
import { UserService } from './user/user.service';
import { Subscription } from 'rxjs';
import { Keepalive } from '@ng-idle/keepalive';
import { Response } from '../models';


@Injectable()
export class RoutingService implements OnDestroy {
    private role = '';
    private modalReference: any;
    sideMenus: Array<any>;
    private sessionRefresh: Subscription;

    constructor(private authService: AuthService, private permissionService: PermissionService,
        private idle: Idle, private router: Router, private modalPopService: ModalPopupService,
        private sharedService: SharedService, private profileService: ProfileService,
        private keepalive: Keepalive, private userService: UserService) {
        this.sideMenus = [];
        this.role = (this.authService.loginRole || this.sharedService.sessionVar);
        if (!this.sharedService.expiryKey ||
            (this.sharedService.expiryKey && new Date().getTime() > new Date(this.sharedService.expiryKey).getTime())) {
            this.logout();
        }
        if (!this.sharedService.sessionVar && this.authService.loginRole) {
            this.logout();
        }
        if (this.sharedService.permissionList.length === 0) {
            this.logout();
        }
    }

    navigateTo(event: NavigationStart): string {
        let url = event.url;
        switch (this.role.toLowerCase()) {
            case 'admin':
            case 'super admin': if (url === '/' || url === '/landing-page' || url === '/login') {
                url = this.defaultRoutebyRole(this.role);
            } else if (url === '/logout') {
                this.logout();
                url = '/';
            } else {
                url = this.checkPermission(url) ? url : '/unauthorized';
            }
                break;
            default:
                url = this.defaultRouteTo(url);
                break;
        }
        return url.toLowerCase();
    }

    // routes to landing-page before login if user is try to access authorized pages
    private defaultRouteTo(url: string): string {
        switch (url) {
            case '/':
            case '/landing-page':
            case '/unauthorized':
            case '/login': break;
            default: url = '/landing-page';
                break;
        }
        return url;
    }

    defaultRoutebyRole(role: string): string {
        this.sharedService.sessionVar = this.role = (role || this.role);
        if (this.sideMenus.length === 0) {
            this.sideMenus = this.permissionService.getNavigationMenus(this.sharedService.permissionList);
            this.sideMenus.push({ title: 'Logout', name: 'Logout', link: ['/logout'], icon: 'ion ion-log-out' });
            this.sideMenus.unshift({ title: 'Dashboard', name: 'Dashboard', group: true });
            this.setSessionTimeout();
        }
        const url = '/' + (this.sideMenus.length > 0 ? this.sideMenus[1].name : 'unauthorized');
        return url.toLowerCase();
    }

    checkPermission(url: string): boolean {
        let hasPermission = false;
        if (this.sideMenus.length === 0) {
            this.sideMenus = this.permissionService.getNavigationMenus(this.sharedService.permissionList);
            this.sideMenus.push({ title: 'Logout', name: 'Logout', link: ['/logout'], icon: 'ion ion-log-out' });
            this.sideMenus.unshift({ title: 'Dashboard', name: 'Dashboard', group: true });
            this.setSessionTimeout();
        }
        this.sideMenus.forEach((menu) => {
            if (url.toLowerCase().indexOf(menu.name.toLowerCase()) > -1) {
                hasPermission = true;
                return hasPermission;
            }
        });
        return hasPermission;
    }

    resetPermission(): void {
        this.role = '';
    }

    setSessionTimeout() {
        this.idle.setIdle(((this.sharedService.generalSettings * 60) || environment.sessionTimeout) - 60);
        this.idle.setTimeout(60);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.watch();
        this.sessionRefresh = this.sharedService.onSessionTimeout(+(this.sharedService.generalSettings || environment.sessionTimeout) / 2)
            .subscribe((interval) => {
                const currentDate = new Date();
                if (interval > 0
                    && (+(currentDate.getHours() + '' + currentDate.getMinutes()) >= this.sharedService.previousInterruption)
                    && !this.sharedService.isApiRequestProcessing) {
                    this.authService.refreshSession(true).subscribe(() => {
                        this.sharedService.onSessionTimeoutReset();
                    });
                } else if (this.sharedService.isApiRequestProcessing) {
                    setTimeout(() => {
                        this.authService.refreshSession(true).subscribe(() => {
                            this.sharedService.onSessionTimeoutReset();
                        });
                    }, 7000);
                }
            });
        this.idle.onIdleStart.subscribe(() => {
            if (!this.modalReference) {
                this.idle.clearInterrupts();
                this.modalReference = this.modalPopService.openPopup<SessionPopupComponent>(SessionPopupComponent, null);
                this.modalReference.afterClosed().subscribe((response) => {
                    if (response) {
                        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
                        this.sharedService.onSessionTimeoutReset();
                        this.idle.watch();
                    } else {
                        this.idle.stop();
                        this.logout();
                        this.router.navigate(['/']);
                    }
                });
            }
        });
        this.idle.onTimeout.subscribe((response) => {
            if (this.modalReference) {
                this.modalReference = undefined;
            }
        });
        this.keepalive.interval(30);
        this.keepalive.onPing.subscribe(() => {
            console.log('Entered onPing()');
            const currDate = new Date();
            this.sharedService.previousInterruption = +(currDate.getHours() + '' + currDate.getMinutes());
        });
    }

    logout() {
        this.resetPermission();
        this.authService.logout();
        this.sideMenus = [];
        this.idle.stop();
        this.profileService.clearProfile();
        if (this.sharedService.userName) {
            this.userService.deleteUserSession(this.sharedService.userName)
                .subscribe((response: Response) => {
                    console.log(`Session deleted status: ${response.result}`);
                });
        }
        this.sharedService.clear();
        if (this.sessionRefresh) {
            this.sessionRefresh.unsubscribe();
        }
    }

    ngOnDestroy() {
        this.sessionRefresh.unsubscribe();
    }
}
