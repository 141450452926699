
const ApiConstants = {
  503: 'Service is unavailable currently, please try after sometime',
  500: 'Due to technical problem unable to serve the request, try after sometime..',
  404: 'Unable to serve your request, resource you requested is not valid..',
  408: 'Request timeout',
  400: 'Due to technical problem unable to serve your request, try again',
  0: 'Not connected to internet'
};
const CognitoConstants = {
  'New_Password_Challenge_Message': 'Password reset confirmation is required, are you sure do you want to reset your password?',
  'Reset_Failure_Message': 'Password reset is not completed, please try again',
  'UserNotFoundException': 'User is not registered with us, register a new account',
  'Invalid_Session': 'Invalid session',
  'CodeMismatchException': 'verification code is invalid, please try again.',
  'NotAuthorizedException': 'Incorrect username or password.',
  'UnknownError': 'The operation couldn\'t be completed. Please try again',
  'RequestTimeout': 'RequestTimeout',
  'InternetNotConnected': 'Not connected to internet',
  'CodeDeliveryFailureException' : 'Verification is not successful. Please try again.',
  'InvalidParameterException' : 'Please provide valid information',
  'NetworkError' : 'The operation couldn\'t be completed. Please try again',
  'AliasExistsException' : 'This Email or Phone already exists',
  'InternalErrorException' : 'Something went wrong. Please try again',
  'InvalidLambdaResponseException' : 'Invalid response',
  'ResourceNotFoundException': 'Requested resource is not valid',
  'TooManyRequestsException' : 'You tried too many times Please try after some time',
  'UnexpectedLambdaException' : 'Something went wrong. Please try again',
  'UserLambdaValidationException' : 'Invalid User',
  'InvalidPasswordException' : 'Please enter valid password',
  'InvalidSmsRoleAccessPolicyException' : 'Something went wrong. Please try again',
  'InvalidSmsRoleTrustRelationshipException' : 'Something went wrong. Please try again',
  'PreconditionNotMetException' : 'Your input is not met the required condition',
  'UnsupportedUserStateException' : 'User is not active. Please contact customer service',
  'UsernameExistsException' : 'User name already exists',
  'InvalidEmailRoleAccessPolicyException' : 'Not allowed to use email',
  'ExpiredCodeException' : 'Your Verification Code is expired, please try again',
  'LimitExceededException' : 'You tried too many times Please try after some time',
  'TooManyFailedAttemptsException' : 'You tried too many times Please try after some time'
};
export const ErrorConstants = {
  Api: ApiConstants,
  Cognito: CognitoConstants
};

